import React from "react";
import { useTranslation } from "react-i18next";
import {
  InfoWrapper,
  KeyText,
  ValueText,
  Wrapper,
} from "src/components/molecules/ReservationComponent/components/PersonComponent/styledComponents";
import ChangeComponent from "src/components/molecules/ReservationComponent/components/ChangeComponent";
import { IPerson } from "src/core/types/personType";

const PersonComponent = ({ person, prevStep }: { person: IPerson; prevStep: () => void }) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { name, email, phone_number } = person;
  return (
    <Wrapper>
      <ChangeComponent type={t("contact_information")} prevStep={prevStep} />
      <InfoWrapper>
        <KeyText>{t("name")}</KeyText>
        <ValueText>{name}</ValueText>
      </InfoWrapper>
      <InfoWrapper>
        <KeyText>{t("email_address")}</KeyText>
        <ValueText>{email}</ValueText>
      </InfoWrapper>
      <InfoWrapper>
        <KeyText>{t("phone_number")}</KeyText>
        <ValueText>{phone_number}</ValueText>
      </InfoWrapper>
    </Wrapper>
  );
};

export default PersonComponent;
