import React from "react";
import { Dot, SectionText, SectionWrapper } from "src/components/pages/PageHome/components";

export const SecondarySection = ({
  text,
  TextAsNode,
  transparentBg,
}: {
  text: string;
  fontSize?: number;
  TextAsNode?: React.ReactNode;
  transparentBg?: boolean;
}) => (
  <SectionWrapper>
    <div>
      <Dot />
    </div>
    <SectionText style={transparentBg ? { backgroundColor: "transparent" } : {}}>
      {TextAsNode || text}
    </SectionText>
  </SectionWrapper>
);
