import styled from "styled-components";

export const SectionTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin: 80px 0 40px 0;

  @media (max-width: 768px) {
    margin: 30px 0;
    gap: 10px;
  }
`;

export const SectionTitle = styled.span`
  font-family: Montserrat, serif;
  font-size: 43px;
  font-weight: 700;
  line-height: 52.42px;
  text-align: center;
  color: #062762;

  @media (max-width: 768px) {
    font-family: Montserrat, serif;
    font-size: 27px;
    font-weight: 700;
    line-height: 32.91px;
    text-align: center;
  }
`;

export const SectionSubTitle = styled.span`
  font-family: Montserrat, serif;
  font-size: 63px;
  font-weight: 700;
  line-height: 76.8px;
  text-align: center;
  color: #e31340;

  @media (max-width: 768px) {
    font-family: Montserrat, serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 39.01px;
    text-align: center;
  }
`;
