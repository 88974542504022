import React from "react";
import { useTranslation } from "react-i18next";
import {
  ActionsWrapper,
  InnerWrapper,
  Col,
  SelectButton,
  TimeRow,
  Wrapper,
} from "src/components/molecules/BundleComponent/components/TableTicket/styledComponents";
import { INote, ISlot, ISlotData, SlotKey } from "core/types/bundles";
import { Loc } from "src/core/types/loc";
import { WarningIcon } from "src/components/icons/tooltipIcons/WarningIcon";
import { Tooltip } from "@mui/material";
import { InfoNoteIcon } from "src/components/icons/tooltipIcons/InfoNoteIcon";

const TableTicket = ({
  slot,
  bundle,
  tour,
  selectSlot,
  loc,
  isAllowed,
  warning,
}: {
  slot: ISlot;
  bundle: ISlotData;
  tour: SlotKey;
  selectSlot: (slot: ISlot, tour: SlotKey) => void;
  loc: Loc;
  isAllowed: boolean;
  warning: boolean;
}) => {
  const warningTextCZ =
    "Čas může být náročné stihnout vzhledem k současně objednané expozici (průměrná délka návštěvy je 40min.)";
  //eslint-disable-next-line  @typescript-eslint/naming-convention
  const { time, story_name, is_guided, language, capacity, story_description } = slot;
  //eslint-disable-next-line  @typescript-eslint/naming-convention
  const { can_have_exposition } = bundle;
  const { t } = useTranslation();

  return (
    <Wrapper isAllowed={isAllowed} warning={warning}>
      <TimeRow>{time.slice(0, 5)}</TimeRow>
      {can_have_exposition && (
        <Col>
          {story_name && story_name[loc]}
          {story_name && (
            <Tooltip title={(story_description && story_description[loc]) || ""} placement="top">
              <InnerWrapper>
                <InfoNoteIcon />
              </InnerWrapper>
            </Tooltip>
          )}
        </Col>
      )}
      <Col>{is_guided ? t("guide") : t("without_guide")}</Col>
      <Col>{language.toUpperCase()}</Col>
      <Col>{`${capacity} ${t("free_spots")}`}</Col>
      <ActionsWrapper>
        {warning && (
          <Tooltip title={warningTextCZ} placement="top">
            <InnerWrapper>
              <WarningIcon />
            </InnerWrapper>
          </Tooltip>
        )}
        <SelectButton isAllowed={isAllowed} onClick={() => isAllowed && selectSlot(slot, tour)}>
          {t("choose")}
        </SelectButton>
      </ActionsWrapper>
    </Wrapper>
  );
};

export default TableTicket;
