import React from "react";
import { gallery } from "src/core/helpers/gallery";

//components
import GalleryComponent from "src/components/atoms/GalleryComponent";
import { Box } from "@mui/material";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
export const Gallery = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Box className={styles.wrapper}>
      {gallery.map((item) => (
        <GalleryComponent
          key={item.id}
          usual={item.usual}
          basicPhoto={item.image}
          gallery={typeof item.image === "object" ? item.image : []}
          headerText={`${t(item.header)}`}
          href={item.href}
        />
      ))}
    </Box>
  );
};
