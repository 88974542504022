export const styles = {
  chip: {
    position: "absolute",
    top: 10,
    right: 10,
    backgroundColor: "rgba(255, 255, 255, 0.90)",
    color: "#E31340",
    fontWeight: "bold",
    fontSize: "16px",
    textTransform: "capitalize",
  },
};
