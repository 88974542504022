import styled from "styled-components";

interface IButtonDecrement {
  isActive: boolean;
}

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 33%;
  gap: 5px;

  @media (max-width: 390px) {
    margin-right: 5px;
  }
`;

export const ButtonDecrement = styled.div<IButtonDecrement>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${(props) => (props.isActive ? "1px solid #E6264C" : "1px solid #b9b9b9")};
  border-radius: 5px;
  text-decoration: none;
  padding: 10px 20px;
  cursor: pointer;
  user-select: none;
  &.muibutton-sizemedium {
    min-width: 40px;
    height: 40px;
  }
`;

export const ButtonCount = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #062762;
  border-radius: 5px;
  text-decoration: none;
  padding: 10px 20px;
  color: #062762;
  font-weight: bold;
  &.muibutton-sizemedium {
    min-width: 40px;
    height: 40px;
  }
  ,
  &.MuiButtonBase-root.MuiButton-root {
    text-decoration: none;
    color: #062762;
    font-weight: bold;
  }
`;

export const ButtonIncrement = styled.div<IButtonDecrement>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${(props) => (props.isActive ? "1px solid #E6264C" : "1px solid #b9b9b9")};
  border-radius: 5px;
  text-decoration: none;
  padding: 10px 20px;
  cursor: pointer;
  user-select: none;
  &.muibutton-sizemedium {
    min-width: 40px;
    height: 40px;
  }
`;
