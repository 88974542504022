export const getDiscountPrice = ({
  price,
  percentage,
}: {
  price: number;
  percentage: number | null;
}) => {
  if (!percentage) return price;
  const discountAmount = (price * percentage) / 100;
  return price - discountAmount;
};
