import React from "react";
import { SelectItemIcon } from "src/components/icons/SelectItemIcon";
import { useTranslation } from "react-i18next";
import {
  ActionsWrapper,
  InnerWrapper,
  Cell,
  SelectButton,
  Time,
  Wrapper,
} from "./styledComponents";
import { INote, ISlot, ISlotData, SlotKey } from "core/types/bundles";
import { Loc } from "src/core/types/loc";
import { WarningIcon } from "src/components/icons/tooltipIcons/WarningIcon";
import { Stack, Tooltip } from "@mui/material";
import { InfoNoteIcon } from "src/components/icons/tooltipIcons/InfoNoteIcon";

export const TableTicketMobile = ({
  slot,
  bundle,
  tour,
  selectSlot,
  loc,
  isAllowed,
  warning,
}: {
  slot: ISlot;
  bundle: ISlotData;
  tour: SlotKey;
  selectSlot: (slot: ISlot, tour: SlotKey) => void;
  loc: Loc;
  isAllowed: boolean;
  warning: boolean;
}) => {
  const warningTextCZ =
    "Čas může být náročné stihnout vzhledem k současně objednané expozici (průměrná délka návštěvy je 40min.)";
  //eslint-disable-next-line  @typescript-eslint/naming-convention
  const { time, story_name, is_guided, language, capacity, story_description } = slot;
  //eslint-disable-next-line  @typescript-eslint/naming-convention
  const { can_have_exposition } = bundle;

  const { t } = useTranslation();

  return (
    <Wrapper isAllowed={isAllowed} warning={warning}>
      <Stack direction="column" gap={1}>
        <Stack direction="row" alignItems="center">
          <Time>{time.slice(0, 5)}</Time>
          {can_have_exposition && (
            <Cell>
              <Stack direction="row" alignItems="center" gap={1}>
                {story_name && story_name[loc]}
                {story_name && (
                  <Tooltip
                    title={(story_description && story_description[loc]) || ""}
                    placement="top"
                  >
                    <InnerWrapper>
                      <InfoNoteIcon />
                    </InnerWrapper>
                  </Tooltip>
                )}
              </Stack>
            </Cell>
          )}
          <Cell>{language.toUpperCase()}</Cell>
        </Stack>

        <Stack direction="row">
          <Cell>{is_guided ? t("guide") : t("without_guide")}</Cell>
          <Cell>{`${capacity} ${t("free_spots")}`}</Cell>
        </Stack>
      </Stack>

      <ActionsWrapper>
        {warning && (
          <Tooltip title={warningTextCZ} placement="top">
            <InnerWrapper>
              <WarningIcon />
            </InnerWrapper>
          </Tooltip>
        )}
        <SelectButton isAllowed={isAllowed} onClick={() => isAllowed && selectSlot(slot, tour)}>
          <SelectItemIcon fill={isAllowed ? "#E31340" : "grey"} />
        </SelectButton>
      </ActionsWrapper>
    </Wrapper>
  );
};
