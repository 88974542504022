import { TGiftCardTiers } from "core/types/giftCardTiers";
import { useQuery } from "react-query";
import axios from "axios";
import { GIFT_CARD_TIERS_ROUTE } from "../endpoints";

export const useGetGiftCardTiers = () => {
  const fetchGiftCardTiers = async (): Promise<TGiftCardTiers> => {
    const res = await axios.get(`${window.BASE_API_URL}/${GIFT_CARD_TIERS_ROUTE}`);
    return res.data;
  };
  return useQuery("fetchGiftCardTiers", fetchGiftCardTiers, {
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    enabled: true,
  });
};
