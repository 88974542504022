import React, { useRef } from "react";

//components
import { Box } from "@mui/material";
import { useDraggable } from "react-use-draggable-scroll";
import styles from "./styles.module.css";
import NoPhoto from "src/components/icons/NoPhoto";

const HorizontalGallery = ({ photos }: { photos: string[] }): JSX.Element => {
  const ref = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(ref);
  return (
    <Box sx={{ maxWidth: "100dvw" }}>
      <Box className={styles.container} {...events} ref={ref}>
        {photos.map((photo, index) => {
          if (photo) {
            return <img key={index} src={photo} alt="" />;
          } else {
            return <NoPhoto key={index} />;
          }
        })}
      </Box>
    </Box>
  );
};

export default HorizontalGallery;
