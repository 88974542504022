import React from "react";

import { useTranslation } from "react-i18next";
import { Box, Stack } from "@mui/material";
import { Section } from "src/components/molecules";
import { StyledParagraphText } from "src/components/atoms/StyledParagraphText";
import { StyledParagraphTextDangerous } from "src/components/atoms/StyledParagraphTextDangerous";

export const MorgueContent = () => {
  const { t } = useTranslation();
  const visitAttractions = [t("tab_morgue_activities_quiet"), t("tab_morgue_activities_music")];

  return (
    <Stack direction="column" mb={2}>
      <StyledParagraphText sx={{ mb: 3, fontWeight: 700 }}>
        {t("tab_morgue_sub_title_bold")}
      </StyledParagraphText>
      <StyledParagraphTextDangerous text={t("tab_morgue_sub_title")} sx={{ mb: 3 }} />
      <StyledParagraphText gutterBottom>{t("tab_morgue_activities_heading")}</StyledParagraphText>
      {visitAttractions.map((text) => (
        <Box sx={{ mb: 0.7 }} key={text}>
          <Section text={text} TextAsNode={<StyledParagraphTextDangerous text={text} />} />
        </Box>
      ))}
      <StyledParagraphText sx={{ color: "#E31340" }}>
        {t("tab_morgue_closed_notice_opening")}
      </StyledParagraphText>
    </Stack>
  );
};
