import React from "react";
import {
  AdditionalInfo,
  ChangeButton,
  ChangeButtonText,
  CheckIconWrapper,
  HistoryItem,
  MainInfo,
  SlotItem,
  SlotWrapper,
  SubWrapper,
  Title,
  TitleText,
  Wrapper,
} from "src/components/molecules/BundleComponent/components/SelectedBundle/styledComponents";
import { ISlot, ISlotData, SlotKey } from "core/types/bundles";
import { Loc } from "src/core/types/loc";
import { CheckIcon } from "src/components/icons/CheckIcon";
import { SmallKalendar } from "src/components/icons/SmallKalendar";
import { SmallVolume } from "src/components/icons/SmallVolume";
import { useTranslation } from "react-i18next";

const SelectedBundle = ({
  slot,
  bundle,
  loc,
  deleteSlot,
  tour,
  is_alone,
}: {
  slot: ISlot | undefined;
  bundle: ISlotData | undefined;
  loc: Loc;
  deleteSlot: (tour: SlotKey) => void;
  tour: SlotKey;
  is_alone: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      {is_alone && (
        <CheckIconWrapper>
          <CheckIcon />
        </CheckIconWrapper>
      )}
      <SubWrapper>
        <Title>
          <TitleText>{(bundle && bundle.title[loc]) || ""}</TitleText>
        </Title>
        <SlotWrapper>
          <MainInfo>
            <SlotItem>
              <SmallKalendar />
              {`${slot?.time.slice(0, 2)}:${slot?.time.slice(3, 5)}` || ""}
            </SlotItem>
            <HistoryItem>{slot && slot.story_name && slot.story_name[loc]}</HistoryItem>
          </MainInfo>
          <AdditionalInfo>
            <SlotItem>{!slot?.is_guided ? t("without_guide") : t("guide_tour")}</SlotItem>
            <SlotItem>
              <SmallVolume />
              {slot?.language.toUpperCase() || "CZ"}
            </SlotItem>
          </AdditionalInfo>
          <ChangeButton onClick={() => deleteSlot(tour)}>
            <ChangeButtonText>{t("to_change")}</ChangeButtonText>
          </ChangeButton>
        </SlotWrapper>
      </SubWrapper>
    </Wrapper>
  );
};

export default SelectedBundle;
