export const styles = {
  bannerIconButton: {
    position: "absolute",
    right: 10,
    top: 10,
    zIndex: 1,
    backgroundColor: "transparent",
    ".MuiSvgIcon-root": {
      fontSize: "2.5rem",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
    "@media (max-width: 768px)": {
      right: 0,
      ".MuiSvgIcon-root": {
        fontSize: "1.8rem",
      },
    },
  },
};
