import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, IconButton } from "@mui/material";

import { CrossIcon } from "src/components/icons/CrossIcon";

import banner from "src/assets/img/homepage/banner.png";
import { styles } from "./styles";

export const FirstVisitBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const hasVisited = localStorage.getItem("hasVisitedHomePage");
    if (!hasVisited) {
      setShowBanner(true);
    }
  }, []);

  const closeBanner = () => {
    setShowBanner(false);
    localStorage.setItem("hasVisitedHomePage", "true");
  };

  return (
    <Dialog
      onClose={closeBanner}
      open={showBanner}
      fullWidth
      PaperProps={{
        style: {
          borderRadius: 10,
          overflow: "hidden",
          margin: "10px",
          width: "unset",
          maxWidth: "1050px",
        },
      }}
    >
      <DialogContent style={{ padding: 0, position: "relative" }}>
        <IconButton onClick={closeBanner} sx={styles.bannerIconButton}>
          <CrossIcon />
        </IconButton>
        <img
          src={banner}
          alt="banner"
          style={{
            width: "100%",
            height: "auto",
            display: "block",
            maxHeight: "88dvh",
            maxWidth: "1050px",
            objectFit: "contain",
          }}
        />
      </DialogContent>
    </Dialog>
  );
};
