import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const SelectItemIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke={props.fill ?? "#E31340"} />
      <path
        d="M14.3667 20L10.5667 16.2L11.5167 15.25L14.3667 18.1L20.4833 11.9833L21.4333 12.9333L14.3667 20Z"
        fill={props.fill ?? "#E31340"}
      />
    </SvgIcon>
  );
};
