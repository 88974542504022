import photoone from "../../assets/Copy of GFX56095-Enhanced-NR.jpg";
import phototwo from "../../assets/FOTO 3b.jpg";
import photothree from "../../assets/GFVisualove_puda_u_Jakuba-23.jpg";
import interiorRedBlue from "../../assets/img/global/church_interior_red_blue_lights.jpg";
import photofive from "../../assets/kostely_Brno_14mm-26.jpg";
import photosix from "../../assets/Náhled Galerie 4.png";
import photosev from "../../assets/P1060835_hdr.jpg";
import photoeight from "../../assets/P1070004_hdr1.jpg";
import photonine from "../../assets/P1070261-1.jpg";
import phototen from "../../assets/Str 3.jpg";
import photoelev from "../../assets/TITULKA 2.jpg";
import phototw from "../../assets/Visualove_puda_u_Jakuba-3.jpg";
import photothr from "../../assets/Visualove-puda-new.jpg";
import photoforth from "../../assets/Visualove_puda_u_Jakuba-10.jpg";
import photofivth from "../../assets/Visualove_puda_u_Jakuba-93.jpg";

export const galleryPhotos = [
  photoone,
  phototwo,
  photothree,
  interiorRedBlue,
  photofive,
  photosev,
  photoeight,
  photonine,
  phototen,
  photosix,
  photoelev,
  phototw,
  photothr,
  photoforth,
  photofivth,
];
