import { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";

import CheckOut from "src/components/organisms/CheckOut";
import { CountdownTimer } from "src/components/molecules/CountdownTimer";

export const PagePurchase = () => {
  const [timer, setTimer] = useState<boolean>(false);
  const [isTabTwoActive, setIsTabTwoActive] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string | null | number>(null);

  //queries
  const PurchaseRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (PurchaseRef && PurchaseRef?.current) {
      window.scrollTo({
        top: PurchaseRef?.current.offsetTop - 200,
        behavior: "smooth",
      });
    }
  }, [currentTab]);

  return (
    <Box>
      {currentTab !== "1" && <CountdownTimer />}
      <CheckOut
        setTimer={setTimer}
        ref={PurchaseRef}
        setIsTabTwoActive={setIsTabTwoActive}
        setCurrentTab={setCurrentTab}
      />
    </Box>
  );
};
