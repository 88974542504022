import React from "react";

export const InfoNoteIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.33337 11.3335H8.66671V7.3335H7.33337V11.3335ZM8.00004 6.00016C8.18893 6.00016 8.34726 5.93627 8.47504 5.8085C8.60282 5.68072 8.66671 5.52239 8.66671 5.3335C8.66671 5.14461 8.60282 4.98627 8.47504 4.8585C8.34726 4.73072 8.18893 4.66683 8.00004 4.66683C7.81115 4.66683 7.65282 4.73072 7.52504 4.8585C7.39726 4.98627 7.33337 5.14461 7.33337 5.3335C7.33337 5.52239 7.39726 5.68072 7.52504 5.8085C7.65282 5.93627 7.81115 6.00016 8.00004 6.00016ZM8.00004 14.6668C7.07782 14.6668 6.21115 14.4918 5.40004 14.1418C4.58893 13.7918 3.88337 13.3168 3.28337 12.7168C2.68337 12.1168 2.20837 11.4113 1.85837 10.6002C1.50837 9.78905 1.33337 8.92239 1.33337 8.00016C1.33337 7.07794 1.50837 6.21127 1.85837 5.40016C2.20837 4.58905 2.68337 3.8835 3.28337 3.2835C3.88337 2.6835 4.58893 2.2085 5.40004 1.8585C6.21115 1.5085 7.07782 1.3335 8.00004 1.3335C8.92226 1.3335 9.78893 1.5085 10.6 1.8585C11.4112 2.2085 12.1167 2.6835 12.7167 3.2835C13.3167 3.8835 13.7917 4.58905 14.1417 5.40016C14.4917 6.21127 14.6667 7.07794 14.6667 8.00016C14.6667 8.92239 14.4917 9.78905 14.1417 10.6002C13.7917 11.4113 13.3167 12.1168 12.7167 12.7168C12.1167 13.3168 11.4112 13.7918 10.6 14.1418C9.78893 14.4918 8.92226 14.6668 8.00004 14.6668ZM8.00004 13.3335C9.48893 13.3335 10.75 12.8168 11.7834 11.7835C12.8167 10.7502 13.3334 9.48905 13.3334 8.00016C13.3334 6.51127 12.8167 5.25016 11.7834 4.21683C10.75 3.1835 9.48893 2.66683 8.00004 2.66683C6.51115 2.66683 5.25004 3.1835 4.21671 4.21683C3.18337 5.25016 2.66671 6.51127 2.66671 8.00016C2.66671 9.48905 3.18337 10.7502 4.21671 11.7835C5.25004 12.8168 6.51115 13.3335 8.00004 13.3335Z"
      fill="#5F6368"
    />
  </svg>
);
