import React from "react";

// components
import { useGiftCardPurchaseContext } from "src/core/context";
import { GIFT_CARD_PURCHASE_ROUTE } from "src/core/routing";

import styles from "./styles.module.css";

// assets
import ticketsIcon from "../../../assets/tickets_white.svg";
import ArrowLeft from "src/components/icons/ArrowLeft";

interface FullScreenTicketButtonProps {
  nextStep: string;
  onClick?: any;
  shouldShowInfo?: boolean;
}

const FullScreenTicketButton: React.FC<FullScreenTicketButtonProps> = ({
  nextStep,
  onClick,
  shouldShowInfo = true,
}): JSX.Element => {
  const { giftCardCount, selectedGiftCard } = useGiftCardPurchaseContext();
  const isGiftCardRoute = location.pathname === GIFT_CARD_PURCHASE_ROUTE;

  const totalTickets = isGiftCardRoute
    ? giftCardCount
    : sessionStorage.getItem("totalTickets") || 0;
  const totalPrice = isGiftCardRoute
    ? giftCardCount * (selectedGiftCard?.monetary_value ?? 0)
    : sessionStorage.getItem("totalPrice") || 0;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onClick) {
      event.preventDefault();
      onClick();
    }
  };

  return (
    <button type="submit" className={styles.wrapper} onClick={handleClick}>
      <div className={styles.sub_wrapper_left}>
        {shouldShowInfo && (
          <>
            <div className={styles.sub_wrapper_left_counter}>
              <span className={styles.text}>{`${totalPrice},- Kč`}</span>
            </div>
            <div className={styles.sub_wrapper_left_sub_content}>
              <img src={ticketsIcon} alt="Tickets Icon" />
              <span className={styles.text}>{totalTickets}</span>
            </div>
          </>
        )}
      </div>
      <div className={styles.sub_wrapper_right}>
        <span className={styles.text}>{nextStep}</span>
        <ArrowLeft color={"#fff"} rotate={"0deg"} />
      </div>
    </button>
  );
};

export default FullScreenTicketButton;
