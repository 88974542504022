import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { ContentPicture } from "src/components/molecules/ContentPicture";
import mainPhoto from "../../../assets/Visualove_puda_u_Jakuba-5.jpg";
import { GiftCardsContent } from "src/components/organisms/GiftCardsContent";

export const PageGiftCards = () => {
  const topRef = useRef<HTMLDivElement>(null);
  const numberRef = useRef<HTMLDivElement | null>(null);
  const phoneButtonCLick = (): void => {
    window.scrollTo({
      top: numberRef.current?.offsetTop,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (topRef && topRef.current) {
      window.scrollTo({
        top: topRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <Box ref={topRef}>
      <ContentPicture
        title={"gift_cards"}
        buttonText={""}
        subtitle={""}
        mainPhoto={mainPhoto}
        click={phoneButtonCLick}
        hasButton={false}
      />
      <GiftCardsContent />
    </Box>
  );
};
