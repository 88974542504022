import { useMutation } from "react-query";
import axios from "axios";
import { TGiftCardPurchaseParams } from "src/core/types";
import { GIFT_CARD_PURCHASE_ROUTE } from "../endpoints";

export const useGiftCardPurchase = () => {
  return useMutation(
    (submitData: TGiftCardPurchaseParams) => {
      return axios.post(`${window.BASE_API_URL}/${GIFT_CARD_PURCHASE_ROUTE}`, submitData);
    },
    {
      onSuccess: (response) => {
        const payUrl = response.data.pay_url;
        localStorage.setItem("gift-res-id", response.data.id);
        sessionStorage.setItem("gift-res-id", response.data.data);
        if (payUrl) {
          localStorage.setItem("gift-res-id", response.data.id);
          window.location.href = payUrl;
        }
      },
    },
  );
};
