import { TourList } from "src/core/types";
import { CounterState } from "src/core/types/action";
import { ISlot, SlotKey } from "core/types/bundles";

export const prepareTicketToIncrement = ({
  ticket,
  tour,
  selectedTickets,
  slot,
}: {
  ticket: TourList;
  selectedTickets: CounterState;
  slot: ISlot;
  tour: SlotKey;
}) => {
  if (!selectedTickets[tour]) {
    return { [tour]: [{ ...ticket, amount: 1, type: ticket.id, time_slot_id: slot.id }] };
  }

  const newState = selectedTickets[tour].map((t) => {
    if (t.type === ticket.id && t.time_slot_id === slot.id) {
      return {
        ...t,
        amount: t.amount + 1,
      };
    }
    return t;
  });

  const ticketExists = newState.some((t) => t.type === ticket.id);

  if (!ticketExists) {
    newState.push({ ...ticket, amount: 1, type: ticket.id, time_slot_id: slot.id });
  }

  return { [tour]: newState };
};
