import React from "react";
import { Button, Typography } from "@mui/material";
import TicketIcon from "../../../assets/tickets_white.svg";
import { useTranslation } from "react-i18next";

import { style } from "src/components/molecules/Header/style";

type TButtonPurchaseTicketsSimpleProps = {
  onClick: () => void;
};

export const ButtonPurchaseTicketsSimple = ({ onClick }: TButtonPurchaseTicketsSimpleProps) => {
  const { t } = useTranslation();
  return (
    <Button
      onClick={onClick}
      sx={{
        ...style.primary,
      }}
    >
      <Typography style={{ fontSize: "17px", cursor: "pointer", padding: "0 8px" }}>
        {t("want_to_buy_button")}
      </Typography>
      <img alt={"ticket"} src={TicketIcon} style={{ padding: "0 8px" }} />
    </Button>
  );
};
