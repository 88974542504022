import React from "react";
import { ISlot, SlotKey, SlotState } from "core/types/bundles";
import { useTranslation } from "react-i18next";
import { SmallKalendar } from "src/components/icons/SmallKalendar";
import { getFormattedDateToText } from "src/core/helpers/getFormattedDateToText";
import { SmallVolume } from "src/components/icons/SmallVolume";
import {
  BlockWithIcon,
  BlockWithIconText,
  BundleName,
  Description,
  InfoWrapper,
  MoreInfo,
  NameAndStory,
  SlotWrapper,
} from "src/components/molecules/DateAndTime/components/PenContent/styledComponents";
import { Loc } from "src/core/types/loc";

const PenContent = ({
  slots,
  date,
  loc,
  getTourName,
}: {
  slots: SlotState;
  date: Date;
  loc: Loc;
  getTourName: (tour: SlotKey) => string;
}) => {
  const { t } = useTranslation();
  const transformedSlots = (Object.keys(slots) as SlotKey[]).map((slot: SlotKey) => slots[slot]);
  return (
    <SlotWrapper>
      {transformedSlots.map((slot: ISlot, i: number) => (
        <InfoWrapper key={slot.id}>
          <NameAndStory>
            <BundleName>{getTourName((Object.keys(slots) as SlotKey[])[i])}</BundleName>
            {slot.story_name && <Description>{slot.story_name[loc]}</Description>}
          </NameAndStory>
          <MoreInfo>
            <BlockWithIcon>
              <SmallKalendar />
              <BlockWithIconText>{`${getFormattedDateToText(date)}, ${slot.time.slice(
                0,
                5,
              )}`}</BlockWithIconText>
            </BlockWithIcon>
            {!slot.is_guided && <Description>{t("without_guide")}</Description>}
            <BlockWithIcon>
              <SmallVolume />
              <BlockWithIconText>{slot.language.toUpperCase()}</BlockWithIconText>
            </BlockWithIcon>
          </MoreInfo>
        </InfoWrapper>
      ))}
    </SlotWrapper>
  );
};

export default PenContent;
