import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import styles from "./styles.module.css";
import jacobPalace from "../../../assets/img/homepage/jacob_palace.jpg";

const HomePicture: React.FC<{
  purchaseTicketsClick?: () => void;
}> = ({ purchaseTicketsClick }) => {
  const { t } = useTranslation();
  const isApp = sessionStorage.getItem("isApp");

  return (
    <Box style={{ marginBottom: "-80px" }}>
      <Box className={[styles.box, isApp === "true" ? styles.removeMarginTop : ""].join(" ")}>
        <Box className={styles.overlayPic}></Box>
        <Box className={styles.imageContainer}>
          <img alt="castlePhoto" src={jacobPalace} className={styles.image} />
        </Box>
        <Box className={styles.overlay}>
          <Box className={styles.textContainer}>
            <span
              className={`${styles.text}`}
              dangerouslySetInnerHTML={{ __html: t("homepage_title") }}
            />
            <br />
            <span
              className={`${styles.sub_text}`}
              dangerouslySetInnerHTML={{ __html: t("homepage_sub_title") }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HomePicture;
