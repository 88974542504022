import React, { useEffect, useState } from "react";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";

import ModalCondition from "src/components/atoms/ModalCondition";
import SecurityVisit from "src/components/atoms/SecurityVisit";
import SecurityData from "src/components/atoms/SecurityData";
import SecurityVisitMobile from "src/components/atoms/SecurityVisitMobile";

import styles from "./styles.module.css";

export const CheckboxComponent: React.FC<{
  onConsentChange: (
    consentKey: "consentOneState" | "consentTwoState" | "consentThreeState",
    value: boolean,
  ) => void;
  consentState: {
    [key in "consentOneState" | "consentTwoState" | "consentThreeState"]: boolean;
  };
}> = ({ onConsentChange, consentState }) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openSecondModal, setOpenSecondModal] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const agreementForm = useForm({
    defaultValues: {
      consentOne: false,
      consentTwo: false,
      consentThree: false,
    },
    reValidateMode: "onChange",
    mode: "onSubmit",
  });
  const { control } = agreementForm;

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return (
    <Box>
      <form>
        <Box className={styles.rowAgreement}>
          <Controller
            name="consentOne"
            control={control}
            rules={{
              required: t("must_agree_to_terms_conditions"),
              validate: (v) => v === true || t("must_agree_to_terms_conditions"),
            }}
            render={() => (
              <FormControlLabel
                label={
                  <div
                    style={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}
                    className={styles.textBlock}
                  >
                    <span className={styles.text}>
                      {`${t("agree_with")} ${t("with")}`}
                      <a
                        target="_blank"
                        href={
                          "https://docs.google.com/document/d/1nAL-uK1yiYK_QTyyxRDXfOo6ZrevjXBPLOUYQ6SEiw8/edit?usp=sharing"
                        }
                        className={styles.underlineText}
                        rel="noreferrer"
                        dangerouslySetInnerHTML={{ __html: t("agreement_with_conditions") }}
                      />
                      <span> {t("and")} </span>
                      <span
                        className={styles.underlineText}
                        onClick={() => setOpenModal(true)}
                        dangerouslySetInnerHTML={{ __html: t("agreement_visit_conditions") }}
                      />
                    </span>
                  </div>
                }
                control={
                  <Checkbox
                    checked={consentState.consentOneState}
                    onChange={(_, value) => onConsentChange("consentOneState", value)}
                  />
                }
              />
            )}
          />
        </Box>
        <ModalCondition open={openModal} handleClose={() => setOpenModal(false)}>
          {({ handleClose }) =>
            isMobile ? (
              <SecurityVisitMobile handleClose={handleClose} />
            ) : (
              <SecurityVisit handleClose={handleClose} />
            )
          }
        </ModalCondition>
        <Box className={styles.rowAgreement}>
          <Controller
            name="consentTwo"
            control={control}
            rules={{
              required: t("must_agree_to_terms_conditions"),
              validate: (v) => v === true || t("must_agree_to_terms_conditions"),
            }}
            render={() => (
              <FormControlLabel
                label={
                  <div style={{ display: "flex" }} className={styles.textBlock}>
                    <span className={styles.text}>
                      {`${t("agree_with")} ${t("with_se")}`}
                      <span
                        className={styles.underlineText}
                        onClick={() => setOpenSecondModal(true)}
                        dangerouslySetInnerHTML={{ __html: t("agreement_with_personal_data") }}
                      />
                    </span>
                  </div>
                }
                control={
                  <Checkbox
                    checked={consentState.consentTwoState}
                    onChange={(_, value) => onConsentChange("consentTwoState", value)}
                  />
                }
              />
            )}
          />
        </Box>
        <ModalCondition open={openSecondModal} handleClose={() => setOpenSecondModal(false)}>
          {({ handleClose }) => <SecurityData handleClose={handleClose} isModal={true} />}
        </ModalCondition>

        <Box className={styles.rowAgreement}>
          <Controller
            name="consentThree"
            control={control}
            render={() => (
              <FormControlLabel
                label={
                  <div className={styles.textBlock}>
                    <span
                      className={styles.text}
                      dangerouslySetInnerHTML={{ __html: t("dicline_from_commercial_offers") }}
                    />
                  </div>
                }
                control={
                  <Checkbox
                    checked={consentState.consentThreeState}
                    onChange={(_, value) => onConsentChange("consentThreeState", value)}
                  />
                }
              />
            )}
          />
        </Box>
      </form>
    </Box>
  );
};
