export const guidedHeader = [
  {
    id: "time",
    label: "Čas",
  },
  {
    id: "story_name",
    label: "Příběh",
  },
  {
    id: "is_guided",
    label: "Prohlídka",
  },
  {
    id: "language",
    label: "Jazyk",
  },
  {
    id: "capacity",
    label: "Volných míst",
  },
];

export const unGuidedHeader = [
  {
    id: "time",
    label: "Čas",
  },
  {
    id: "is_guided",
    label: "Prohlídka",
  },
  {
    id: "language",
    label: "Jazyk",
  },
  {
    id: "capacity",
    label: "Volných míst",
  },
];
