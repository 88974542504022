import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { ContentPicture } from "src/components/molecules/ContentPicture";
import { TravelContent } from "src/components/atoms/TravelContent";
import TravelForm from "src/components/atoms/TravelForm";
import { RecapPayment } from "src/components/molecules/RecapPayment";
import { useFormValuesContext } from "src/core/context";
import { Panel } from "src/components/molecules/Panel";
import Addvert from "src/components/molecules/Advertisement";
import { CheckOutStep, Tab } from "src/core/types";
import { visitorsCategories } from "src/core/lists";
import { ICheckOutState } from "components/organisms";

//assets
import mobilePhoto from "../../../assets/Visualove_puda_u_Jakuba-5.jpg";
import TiketIcon from "../../../assets/tickets_white.svg";
import grayPersonal from "src/assets/kontaktni_udaje_svetle_sede.svg";
import colorPersonal from "src/assets/kontaktni_udaje_cervene.svg";
import bluePersonal from "src/assets/kontaktni_udaje_sede.svg";
import grayPayment from "src/assets/payment_gray.svg";
import colorPayment from "src/assets/payment_color.svg";
import bluePayment from "src/assets/payment_blue.svg";

import purchaseStyle from "src/components/atoms/TravelContent/purchaseStyle.module.css";
import styles from "./styles.module.css";

export const PageTravel = (): JSX.Element => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const { formValues, setFormValues } = useFormValuesContext();
  const formRef = useRef<HTMLDivElement>(null);
  const topRef = useRef<HTMLDivElement>(null);

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    setFormValues({ ...formValues, finalPrice: visitorsCategories[6].price });
    sessionStorage.setItem("totalPrice", String(visitorsCategories[6].price));
  }, [formValues, setFormValues]);

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  const mainPhoto = isMobile ? mobilePhoto : mobilePhoto;
  const FormRef = useRef<HTMLDivElement | null>(null);
  const [activeTab] = useState<string>("recap_and_payment_title");

  const [checkOutState, setCheckOutState] = useState<ICheckOutState>({
    tabs: [
      {
        title: t("contact_information"),
        icon: grayPersonal,
        activeIcon: colorPersonal,
        doneIcon: bluePersonal,
        isTabActive: true,
        isTabDone: false,
        key: "1",
      },
      {
        title: t("recap_and_payment"),
        icon: grayPayment,
        activeIcon: colorPayment,
        doneIcon: bluePayment,
        isTabActive: false,
        isTabDone: false,
        key: "2",
      },
    ],
    panels: [
      {
        description: t("contact_information"),
        isEnabled: true,
        key: "1",
        component: TravelForm,
        isStepFinished: false,
        isPen: false,
      },
      {
        description: t("recap_and_payment"),
        isEnabled: false,
        key: "2",
        component: RecapPayment,
        isStepFinished: false,
        isPen: false,
      },
    ],
  });

  const updateCheckOutState = (newTabsState: Tab[], newPanelsState: CheckOutStep[]) => {
    setCheckOutState((prevState) => {
      return {
        ...prevState,
        tabs: newTabsState,
        panels: newPanelsState,
      };
    });
  };

  const FormClick = () => {
    if (FormRef.current) {
      const elementPosition = FormRef.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - 20;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const handleButtonClick = (): void => {
    if (formRef && formRef.current) {
      window.scrollTo({
        top: formRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (topRef && topRef.current) {
      window.scrollTo({
        top: topRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <Box ref={topRef}>
      <ContentPicture
        buttonText={"purchase_button"}
        mainPhoto={mainPhoto}
        title={"kostel_title"}
        subtitle={"for_groups"}
        click={handleButtonClick}
        buttonIcon={TiketIcon}
      />
      <TravelContent handleButtonClick={handleButtonClick} formClick={FormClick} />
      <Box className={styles.wrapper} ref={formRef}>
        <Panel tabs={checkOutState?.tabs} style={{ marginTop: "10px" }} />
      </Box>
      {checkOutState?.panels?.map((step: CheckOutStep) => {
        const Component = step.component;
        return (
          <Component
            key={step.key}
            step={step}
            tabs={checkOutState.tabs}
            activeTab={activeTab}
            panels={checkOutState.panels}
            updateCheckOutState={updateCheckOutState}
            checkOutState={checkOutState}
          />
        );
      })}
      <div className={purchaseStyle.addvert}>
        <Addvert background={"#F8F8F8"} />
      </div>
    </Box>
  );
};
