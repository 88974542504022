import { TourList } from "core/types";
import { CounterState } from "core/types/action";
import { ISlot, SlotKey } from "core/types/bundles";

export const prepareTicketToDecrement = ({
  ticket,
  tour,
  selectedTickets,
  slot,
}: {
  ticket: TourList;
  selectedTickets: CounterState;
  slot: ISlot;
  tour: SlotKey;
}) => {
  if (!selectedTickets[tour]) {
    return selectedTickets;
  }

  const newState = selectedTickets[tour]
    .map((t) => {
      if (t.type === ticket.id && t.time_slot_id === slot.id) {
        if (t.amount === 1) return null;
        return { ...t, amount: t.amount - 1 };
      }
      return t;
    })
    .filter((t) => t !== null);
  return {
    [tour]: newState,
  };
};
