import styled from "styled-components";

export const Wrapper = styled.div`
  min-height: 460px;
  min-width: 300px;
  width: 413px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 24px 0;
  gap: 16px;
  border-radius: 30px;
  border: 2px solid #e1e1e1;
  position: relative;
  @media (max-width: 1400px) {
    min-height: 420px;
    width: 100%;
    padding: 0 0 24px 0;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 196px;
  min-height: 196px;
  object-fit: cover;
  border-radius: 30px;
  @media (max-width: 1400px) {
    min-height: 140px;
    height: 140px;
  }
`;

export const ItemsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  gap: 10px;
  @media (max-width: 1400px) {
    padding: 0 16px;
  }
`;

export const Price = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.5px;
  text-align: left;
  color: #000000;
`;

export const Item = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const GreyLine = styled.div`
  width: 85%;
  height: 1px;
  border-bottom: 1px solid #e1e1e1;
`;

export const DiscountWrapper = styled.div`
  width: 100%;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -10px;
  margin-bottom: 20px;
  @media (max-width: 1400px) {
    padding: 0 16px;
  }
`;

export const DiscountText = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #e31340;
`;

export const DiscountPercent = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: right;
  color: #e31340;
`;

export const PriceWrapper = styled.div`
  width: 100%;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @media (max-width: 1400px) {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;

    :last-child {
      align-self: flex-end;
    }
  }
`;

export const PriceSubWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const PriceText = styled.span`
  font-family: Montserrat, serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 48.76px;
  text-align: left;
  color: #e31340;
`;

export const SelectButton = styled.div`
  width: 161px;
  height: 50px;
  display: flex;
  justify-content: center;
  justify-self: flex-end;
  align-items: center;
  padding: 13px 24px 13px 24px;
  gap: 10px;
  border-radius: 25px;
  border: 1px solid #e31340;
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #e31340;
  cursor: pointer;

  &:hover {
    background: #e31340;
    color: #ffffff;
  }
`;

export const AdditionalPrice = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  color: #e31340;
`;

export const SecondPartWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;
