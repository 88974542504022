import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const TimerIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" fill="none">
      <path
        d="M15.3812 4.71429C15.0312 4.71429 14.7354 4.59345 14.4937 4.35179C14.2521 4.11012 14.1312 3.81429 14.1312 3.46429C14.1312 3.11429 14.2521 2.81845 14.4937 2.57679C14.7354 2.33512 15.0312 2.21429 15.3812 2.21429C15.7312 2.21429 16.0271 2.33512 16.2687 2.57679C16.5104 2.81845 16.6312 3.11429 16.6312 3.46429C16.6312 3.81429 16.5104 4.11012 16.2687 4.35179C16.0271 4.59345 15.7312 4.71429 15.3812 4.71429ZM15.9074 16.7L11.6074 12.4V7H13.6074V11.6L17.3074 15.3L15.9074 16.7Z"
        fill={props.fill ?? "#E31340"}
      />
      <path
        d="M18.5413 6.92321C18.783 7.16488 19.0788 7.28571 19.4288 7.28571C19.7788 7.28571 20.0747 7.16488 20.3163 6.92321C20.558 6.68155 20.6788 6.38571 20.6788 6.03571C20.6788 5.68571 20.558 5.38988 20.3163 5.14821C20.0747 4.90655 19.7788 4.78571 19.4288 4.78571C19.0788 4.78571 18.783 4.90655 18.5413 5.14821C18.2997 5.38988 18.1788 5.68571 18.1788 6.03571C18.1788 6.38571 18.2997 6.68155 18.5413 6.92321Z"
        fill={props.fill ?? "#E31340"}
      />
      <path
        d="M20.2556 11.2089C20.4973 11.4506 20.7931 11.5714 21.1431 11.5714C21.4931 11.5714 21.7889 11.4506 22.0306 11.2089C22.2723 10.9673 22.3931 10.6714 22.3931 10.3214C22.3931 9.97143 22.2723 9.67559 22.0306 9.43393C21.7889 9.19226 21.4931 9.07143 21.1431 9.07143C20.7931 9.07143 20.4973 9.19226 20.2556 9.43393C20.0139 9.67559 19.8931 9.97143 19.8931 10.3214C19.8931 10.6714 20.0139 10.9673 20.2556 11.2089Z"
        fill={props.fill ?? "#E31340"}
      />
      <path
        d="M12.6074 22C11.2241 22 9.92409 21.7375 8.70742 21.2125C7.49076 20.6875 6.43242 19.975 5.53242 19.075C4.63242 18.175 3.91992 17.1167 3.39492 15.9C2.86992 14.6833 2.60742 13.3833 2.60742 12C2.60742 10.6167 2.86992 9.31667 3.39492 8.1C3.91992 6.88333 4.63242 5.825 5.53242 4.925C6.43242 4.025 7.49076 3.3125 8.70742 2.7875C9.92409 2.2625 11.2241 2 12.6074 2V4C10.3741 4 8.48242 4.775 6.93242 6.325C5.38242 7.875 4.60742 9.76667 4.60742 12C4.60742 14.2333 5.38242 16.125 6.93242 17.675C8.48242 19.225 10.3741 20 12.6074 20C13.4574 20 14.2699 19.875 15.0449 19.625C15.8199 19.375 17.1824 18.575 17.1824 18.575C17.1824 18.575 20.2129 16.4054 20.5484 13.5751C20.9151 13.6418 21.1616 13.7417 21.4699 13.925C21.7783 14.1083 22.0457 14.3305 22.2824 14.6C21.6743 16.9427 19.9137 18.9849 18.8574 19.825C17.8011 20.6651 17.0533 21.0417 15.9949 21.425C14.9366 21.8083 13.8074 22 12.6074 22Z"
        fill={props.fill ?? "#E31340"}
      />
    </SvgIcon>
  );
};
