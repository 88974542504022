import { ISlotData } from "core/types/bundles";
import { Action, TourState, TourType } from "core/types/reduxTypes";

const initialState: TourState = {} as TourState;

// eslint-disable-next-line @typescript-eslint/default-param-last
export const SelectedToursReducer = (state = initialState, action: Action<TourType, ISlotData>) => {
  switch (action.type) {
    case "SET_TOUR":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
