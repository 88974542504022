import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const MouseCursorIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M8 13.75L9.975 11H14.225L8 6.1V13.75ZM13.775 22L10.15 14.2L6 20V2L20 13H12.9L16.5 20.725L13.775 22Z"
        fill={props.fill ?? "#E31340"}
      />
    </SvgIcon>
  );
};
