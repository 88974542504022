import React from "react";
import { Stack, SxProps, Theme, Typography } from "@mui/material";

type TIconPrefixedTextProps = {
  imgSrc?: string;
  text: string;
  icon?: React.ReactNode;
  sx?: SxProps<Theme>;
  textSx?: SxProps<Theme>;
  dangerouslySet?: boolean;
};

export const IconPrefixedText = ({
  imgSrc,
  text,
  icon,
  sx,
  textSx,
  dangerouslySet = false,
}: TIconPrefixedTextProps) => {
  return (
    <Stack direction="row" alignItems="top" gap={2} sx={sx}>
      {imgSrc && <img src={imgSrc} alt="icon" />}
      {icon}
      {dangerouslySet ? (
        <Typography sx={textSx} dangerouslySetInnerHTML={{ __html: text }} />
      ) : (
        <Typography sx={textSx}>{text}</Typography>
      )}
    </Stack>
  );
};
