import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Button, Grid, Skeleton, Stack, Typography } from "@mui/material";
import { GIFT_CARD_PURCHASE_ROUTE } from "src/core/routing";
import { useGiftCardPurchaseContext } from "src/core/context";
import { Counter, GiftCardPriceBadge, SecondarySection } from "src/components";
import { useGetGiftCardTiers } from "src/core/queries/useGetGiftCardTiers";
import { Loc } from "src/core/types/loc";

import giftCards from "../../../assets/img/gift_cards/gift_cards_optimized.png";
import { styles } from "./styles";

export const GiftCardPicker = () => {
  const { data, isLoading, isError } = useGetGiftCardTiers();
  const { giftCardCount, setGiftCardCount, selectedGiftCard, setSelectedGiftCard } =
    useGiftCardPurchaseContext();

  const loc: Loc = (sessionStorage.getItem("loc") as Loc) || "cs";
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setSelectedGiftCard(data?.tiers[2]);
  }, [data?.tiers, setSelectedGiftCard]);

  // reset the counter when user enters the subpage
  useEffect(() => {
    setGiftCardCount(0);
  }, [setGiftCardCount]);

  const handleCounterIncrement = () => {
    if (giftCardCount <= 0) {
      setGiftCardCount(1);
      return;
    }
    setGiftCardCount(giftCardCount + 1);
  };

  const handleCounterDecrement = () => {
    if (giftCardCount <= 0) {
      return;
    }
    setGiftCardCount(giftCardCount - 1);
  };

  const handlePurchaseGiftCardButtonClick = () => {
    navigate(GIFT_CARD_PURCHASE_ROUTE);
  };

  return (
    <Stack direction="row" py={3} alignItems="center" sx={styles.giftCardPicker}>
      <img src={giftCards} alt="gift cards" />
      <Stack direction="column" justifyContent="center" alignItems="start" gap={2}>
        <Stack direction="column" justifyContent="center" alignItems="start" gap={2} mt={3}>
          <Typography variant="h2" fontSize={{ xs: "22px", md: "40px" }} my={2}>
            {t("gift_card_for_james_church_heading")}
          </Typography>
          <Grid
            container
            gap={2}
            justifyContent={{ xs: "center", sm: "start" }}
            alignItems="center"
          >
            {isLoading
              ? [...Array(6)].map((_, i) => <Skeleton key={i} width="160px" height="65px" />)
              : data?.tiers.map((tier) => {
                  return (
                    <GiftCardPriceBadge
                      key={tier.name[loc]}
                      label={tier.name[loc]}
                      price={tier.monetary_value}
                      isSelected={selectedGiftCard?.name[loc] === tier.name[loc]}
                      onClick={() => setSelectedGiftCard(tier)}
                    />
                  );
                })}
          </Grid>
          {isLoading ? (
            <Skeleton width="50%" height="300px" />
          ) : (
            <Stack
              direction="column"
              justifyContent="space-between"
              alignItems="start"
              width="100%"
              height="100%"
              sx={{
                border: "1px solid grey",
                borderRadius: "10px",
                width: "100%",
                maxWidth: "600px",
                minHeight: "300px",
              }}
              p={2}
            >
              <Stack direction="column" justifyContent="space-between" alignItems="start">
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  mb={4}
                  color="#000 !important"
                  fontSize="18px"
                >
                  {t("can_be_used_on")}
                </Typography>

                <Stack ml={3} direction="column" gap={1}>
                  {selectedGiftCard?.description[loc].map((purpose) => {
                    return <SecondarySection key={purpose} text={purpose} transparentBg />;
                  })}
                </Stack>
              </Stack>
              <Stack direction="column" width="100%">
                <Box sx={styles.line} />
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="h3" fontWeight="bold">
                    {t("price")}
                  </Typography>
                  <Typography variant="h2" fontSize={{ xs: "22px", md: "40px" }}>
                    {selectedGiftCard?.monetary_value} {t("cz_currency")}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>
        <Stack direction="row" justifyContent="end" width="100%" alignItems="center" gap={3}>
          <Box maxWidth="150px">
            <Counter
              count={giftCardCount}
              isButtonsAvailable={true}
              handleDecrement={handleCounterDecrement}
              handleIncrement={handleCounterIncrement}
            />
          </Box>
          <Button
            variant="contained"
            onClick={handlePurchaseGiftCardButtonClick}
            disabled={giftCardCount <= 0}
          >
            {t("buy_gift_card")}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
