import React from "react";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import { styles } from "./styles";
import styless from "./styles.module.css";
import { CrossThinIcon } from "src/components/icons/CrossThinIcon";

interface ModalComponentProps {
  text: string;
  open: boolean;
  handleClose: () => void;
}

export const ModalComponent: React.FC<ModalComponentProps> = ({ text, open, handleClose }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={styless.wrapper}>
        <IconButton sx={styles.cancelButton} onClick={() => handleClose()}>
          <CrossThinIcon />
        </IconButton>
        <Typography fontWeight={400} variant="body1">
          {text.split("\n").map((paragraph, index) => (
            <p key={index}>{paragraph.trim()}</p>
          ))}
        </Typography>
      </Box>
    </Modal>
  );
};
