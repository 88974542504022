import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const QrCodeIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none">
      <path
        d="M17.3333 28V25.3333H20V28H17.3333ZM14.6667 25.3333V18.6667H17.3333V25.3333H14.6667ZM25.3333 21.3333V16H28V21.3333H25.3333ZM22.6667 16V13.3333H25.3333V16H22.6667ZM6.66667 18.6667V16H9.33333V18.6667H6.66667ZM4 16V13.3333H6.66667V16H4ZM16 6.66667V4H18.6667V6.66667H16ZM6 10H10V6H6V10ZM4 12V4H12V12H4ZM6 26H10V22H6V26ZM4 28V20H12V28H4ZM22 10H26V6H22V10ZM20 12V4H28V12H20ZM22.6667 28V24H20V21.3333H25.3333V25.3333H28V28H22.6667ZM17.3333 18.6667V16H22.6667V18.6667H17.3333ZM12 18.6667V16H9.33333V13.3333H17.3333V16H14.6667V18.6667H12ZM13.3333 12V6.66667H16V9.33333H18.6667V12H13.3333ZM7 9V7H9V9H7ZM7 25V23H9V25H7ZM23 9V7H25V9H23Z"
        fill={props.fill ?? "#E31340"}
      />
    </SvgIcon>
  );
};
