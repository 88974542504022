import { SlotDay, SlotKey } from "core/types/bundles";
import { useQuery } from "react-query";
import axios from "axios";
import { MONTH_API_ROUTE } from "../endpoints";

export const useGetSlotsMonthly = ({
  month,
  pos,
  tours,
}: {
  month: string;
  pos: string;
  tours: SlotKey[];
}) => {
  const fetchSlots: () => Promise<SlotDay[]> = async () => {
    const res = await axios.post(`${window.BASE_API_URL}/${MONTH_API_ROUTE}/`, {
      month: month,
      pos: pos,
      tours: tours,
    });
    return res.data.days;
  };

  return useQuery(`fetchSlotsMonthly-${JSON.stringify(tours)}-${month}`, fetchSlots, {
    refetchOnWindowFocus: false,
    enabled: true,
  });
};
