export const headerRoutes: { path: string; label: string; icon?: string }[] = [
  {
    path: "/group",
    label: "travel_action",
    icon: undefined,
  },
  {
    path: "/school",
    label: "schools",
    icon: undefined,
  },
  {
    path: "/travel",
    label: "travel_agencies",
    icon: undefined,
  },
  {
    path: "/guided-tours",
    label: "guided_tours",
    icon: undefined,
  },
];
