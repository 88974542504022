import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const PhoneIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none">
      <path
        d="M9.33268 30.6668C8.59935 30.6668 7.97157 30.4057 7.44935 29.8835C6.92713 29.3613 6.66602 28.7335 6.66602 28.0002V4.00016C6.66602 3.26683 6.92713 2.63905 7.44935 2.11683C7.97157 1.59461 8.59935 1.3335 9.33268 1.3335H22.666C23.3993 1.3335 24.0271 1.59461 24.5494 2.11683C25.0716 2.63905 25.3327 3.26683 25.3327 4.00016V28.0002C25.3327 28.7335 25.0716 29.3613 24.5494 29.8835C24.0271 30.4057 23.3993 30.6668 22.666 30.6668H9.33268ZM9.33268 24.0002V28.0002H22.666V24.0002H9.33268ZM15.9993 27.3335C16.3771 27.3335 16.6938 27.2057 16.9493 26.9502C17.2049 26.6946 17.3327 26.3779 17.3327 26.0002C17.3327 25.6224 17.2049 25.3057 16.9493 25.0502C16.6938 24.7946 16.3771 24.6668 15.9993 24.6668C15.6216 24.6668 15.3049 24.7946 15.0493 25.0502C14.7938 25.3057 14.666 25.6224 14.666 26.0002C14.666 26.3779 14.7938 26.6946 15.0493 26.9502C15.3049 27.2057 15.6216 27.3335 15.9993 27.3335ZM9.33268 21.3335H22.666V8.00016H9.33268V21.3335ZM9.33268 5.3335H22.666V4.00016H9.33268V5.3335Z"
        fill={props.fill ?? "#E31340"}
      />
    </SvgIcon>
  );
};
