import {
  BundleReducer,
  CapacityReducer,
  GalleryReducer,
  ReservationReducer,
  SelectedTicketReducer,
  TimeSlotReducer,
  TravelFormReducer,
  ReservationDataReducer,
  SelectedToursReducer,
} from "src/core/redux/reducers";

const reducers = {
  selectedTicket: SelectedTicketReducer,
  travelForm: TravelFormReducer,
  reservationID: ReservationReducer,
  gallery: GalleryReducer,
  bundle: BundleReducer,
  timeSlot: TimeSlotReducer,
  capacity: CapacityReducer,
  reservationData: ReservationDataReducer,
  selectedTours: SelectedToursReducer,
};

export default reducers;
