import { Counter } from "src/components/atoms/Counter";
import React, { useEffect, useState } from "react";
import {
  DescriptionMessage,
  ErrorMessage,
  ErrorWrapper,
  Price,
  PriceDescription,
  PriceWrapper,
  SubTitle,
  SubTitleBox,
  SubTitleTwo,
  Wrapper,
  WrapperWithError,
} from "src/components/molecules/AmountPeopleComponent/styledComponents";
import { useTranslation } from "react-i18next";
import { IBundle } from "core/types/bundles";
import { getDiscountPrice } from "src/core/utils/getDiscountPrice";
import { Loc } from "src/core/types/loc";
import { ticketDescriptions, ticketTypes } from "src/core/lists/ticketTypes";
import { TourName } from "src/core/types";

interface IAmountPeopleComponent {
  title: string;
  price: number;
  count: number;
  increment: () => void;
  decrement: () => void;
  status: string;
  isError: boolean | null;
  errorMessage: string | null;
  isPermanentMessage?: boolean;
  bundle: IBundle;
}

const AmountPeopleComponent = ({
  title,
  price,
  count,
  increment,
  decrement,
  status,
  isError,
  errorMessage,
  isPermanentMessage,
  bundle,
}: IAmountPeopleComponent): JSX.Element => {
  const { t } = useTranslation();
  const loc: Loc = (sessionStorage.getItem("loc") as Loc) || "cs";
  const { discount } = bundle;

  const [isButtonsAvailable, setIsButtonsAvailable] = useState<boolean>(true);

  const handleLocalIncrement = () => {
    increment();
  };

  const handleLocalDecrement = () => {
    decrement();
  };

  useEffect(() => {
    if (status === "success" || status === "idle") {
      setTimeout(() => {
        setIsButtonsAvailable(true);
      }, 0.5);
    } else {
      setIsButtonsAvailable(false);
    }
  }, [status]);

  return (
    <WrapperWithError>
      <Wrapper error={isError || false}>
        <SubTitleBox isDE={loc === "de"}>
          <SubTitle isDE={loc === "de"}>{t(ticketTypes[title as TourName])}</SubTitle>
          <SubTitleTwo>{t(ticketDescriptions[title as TourName])}</SubTitleTwo>
        </SubTitleBox>
        <PriceWrapper>
          <Price isDiscount={!!discount && price !== 0} isDE={loc === "de"}>{`${
            discount ? getDiscountPrice({ price: price, percentage: discount }) : price
          } ${t("cz_currency")}`}</Price>
          {!!discount && <PriceDescription>{`${price} ${t("cz_currency")}`}</PriceDescription>}
        </PriceWrapper>

        <Counter
          count={count}
          isButtonsAvailable={isButtonsAvailable}
          handleDecrement={handleLocalDecrement}
          handleIncrement={handleLocalIncrement}
        />
      </Wrapper>
      <ErrorWrapper>
        {isPermanentMessage && !isError && (
          <DescriptionMessage>{errorMessage && errorMessage}</DescriptionMessage>
        )}
        {isError && <ErrorMessage>{errorMessage && errorMessage}</ErrorMessage>}
      </ErrorWrapper>
    </WrapperWithError>
  );
};

export default AmountPeopleComponent;
