import { useMutation } from "react-query";
import axios from "axios";
import { PRE_RESERVATION_API_ROUTE } from "../endpoints";
import { IPreReservation, IPreReservationResponse, SlotKey } from "core/types/bundles";

export const usePostPreReservation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (
    data: IPreReservationResponse,
    transformedData?: IPreReservation,
    tour?: SlotKey,
    slotId?: number,
  ) => void;
  onError?: () => void;
}) => {
  return useMutation(
    ({ transformedData }: { transformedData: IPreReservation; tour?: SlotKey; slotId?: number }) =>
      axios.post(`${window.BASE_API_URL}/${PRE_RESERVATION_API_ROUTE}`, transformedData),
    {
      onSuccess: (data, variables?) => {
        if (onSuccess)
          onSuccess(data.data, variables?.transformedData, variables?.tour, variables?.slotId);
      },
      onError: () => {
        if (onError) onError();
      },
    },
  );
};
