import { Box } from "@mui/material";
import { GIFT_CARD_PURCHASE_ROUTE } from "src/core/routing";
import React, { useEffect, useState } from "react";
import TiketIcon from "../../../assets/ticket_red.svg";
import arrow from "../../../assets/arrowSvg.svg";
import { useTranslation } from "react-i18next";
import { Tab } from "src/core/types";
import checkMark from "../../../assets/checkmark (1).svg";
import { styles } from "./styles";
import css_style from "./styles.module.css";
import separatorBlue from "../../../assets/arrowSvg blue.svg";
import separatorBlueLong from "../../../assets/BlueArrowLong.svg";
import separatorBlueLonger from "../../../assets/BlueRow.svg";
import longArrow from "../../../assets/Group 204.svg";
import mediumArrow from "../../../assets/ArrowMedium.svg";
import { useLocation } from "react-router-dom";
import { CountdownTimer } from "src/components/molecules/CountdownTimer";

interface PanelProps {
  tabs: Tab[];
  style: any;
}

export const Panel: React.FC<PanelProps> = ({ tabs, style }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isApp = sessionStorage.getItem("isApp");
  const [isTabTwoActive, setIsTabTwoActive] = useState<boolean>(false);
  //middle version
  const [isMiddle, setIsMiddle] = useState(window.innerWidth < 1280 && window.innerWidth > 420);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 420);
  const updateisMiddle = () => {
    setIsMiddle(window.innerWidth < 1280 && window.innerWidth > 420);
  };
  useEffect(() => {
    window.addEventListener("resize", updateisMiddle);
    return () => window.removeEventListener("resize", updateisMiddle);
  }, []);

  //mobile version
  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 420);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  useEffect(() => {
    const foundTab = tabs?.find((tab) => tab.key === "2");
    const isTabActive = foundTab?.isTabActive || false;
    setIsTabTwoActive(isTabActive);
  }, [tabs]);

  const getStyle = (): string => {
    switch (location.pathname) {
      case "/travel":
        return `${css_style.relative}`;
      default:
        if (isApp) {
          return `${css_style.wrapper_mobile}`;
        } else {
          return `${css_style.wrapper}`;
        }
    }
  };
  const getStyleTitle = (): string => {
    switch (location.pathname) {
      case "/travel":
        return `${css_style.title_mutated}`;
      default:
        return css_style.title_wrapper;
    }
  };

  const getTitleText = (): string => {
    switch (location.pathname) {
      case GIFT_CARD_PURCHASE_ROUTE:
        return t("purchase_gift_card_title");
      default:
        return t("purchase_title");
    }
  };

  const renderIcon = (tab: Tab) => {
    if (tab.isTabActive) return <img src={tab.activeIcon} alt={tab.title} />;
    else if (tab.isTabDone)
      return (
        <>
          <img src={tab.doneIcon} alt={tab.title} />
          <img
            src={checkMark}
            style={{
              position: "absolute",
              top: "50%",
              left: "100%",
              transform: "translate(-50%, -50%)",
              zIndex: "10",
            }}
          />
        </>
      );
    else return <img src={tab.icon} alt={tab.title} />;
  };

  return (
    <Box className={getStyle()}>
      <Box className={getStyleTitle()}>
        <img src={TiketIcon} style={{ marginRight: "15px", width: "24px" }} />
        <span className={css_style.titleText}>{getTitleText()}</span>
      </Box>
      <Box style={style} className={css_style.box}>
        <Box className={css_style.panelWrapper}>
          {tabs?.map((tab, index) => {
            return (
              <React.Fragment key={tab.title}>
                {isMiddle ? (
                  <>
                    <Box
                      className={css_style.iconGrey}
                      sx={{
                        ...styles.iconGreyMobile,
                        ...(tab.isTabActive ? styles.isTabActive : {}),
                        ...(tab.isTabDone ? styles.isTabDone : {}),
                      }}
                    >
                      {renderIcon(tab)}
                    </Box>
                    {index < tabs.length - 1 && (
                      <>
                        {tab.isTabDone ? (
                          <img
                            src={
                              tabs.length < 4
                                ? longArrow
                                : isMobile
                                ? separatorBlue
                                : separatorBlueLonger
                            }
                            style={styles.separatorMobile}
                          />
                        ) : (
                          <img
                            src={
                              tabs.length < 4
                                ? location.pathname === "/travel"
                                  ? longArrow
                                  : mediumArrow
                                : arrow
                            }
                            style={styles.separatorMobile}
                          />
                        )}
                      </>
                    )}
                  </>
                ) : isMobile ? (
                  <>
                    <Box
                      className={css_style.iconGreySmall}
                      sx={{
                        ...styles.iconGreyMobileSmall,
                        ...(tab.isTabActive ? styles.isTabActive : {}),
                        ...(tab.isTabDone ? styles.isTabDone : {}),
                      }}
                    >
                      {renderIcon(tab)}
                    </Box>
                    {index < tabs.length - 1 && (
                      <>
                        {tab.isTabDone ? (
                          <img
                            src={
                              tabs.length < 4
                                ? mediumArrow
                                : isMobile
                                ? separatorBlue
                                : separatorBlueLonger
                            }
                            style={styles.separatorMobile}
                          />
                        ) : (
                          <img
                            src={tabs.length < 4 ? mediumArrow : arrow}
                            style={styles.separatorMobile}
                          />
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Box
                      className={css_style.iconGrey}
                      sx={{
                        ...styles.iconGrey,
                        ...(tab.isTabActive ? styles.isTabActive : {}),
                        ...(tab.isTabDone ? styles.isTabDone : {}),
                      }}
                    >
                      {renderIcon(tab)}
                      <span style={{ fontWeight: "600" }}>{t(tab.title)}</span>
                    </Box>
                    {index < tabs.length - 1 && (
                      <>
                        {tab.isTabDone ? (
                          <img
                            src={
                              isMobile
                                ? separatorBlue
                                : location.pathname === "/travel"
                                ? separatorBlueLong
                                : separatorBlueLonger
                            }
                            style={styles.separator}
                          />
                        ) : (
                          <img
                            src={location.pathname === "/travel" ? longArrow : mediumArrow}
                            style={styles.separator}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </React.Fragment>
            );
          })}
          {isTabTwoActive && location.pathname !== "/travel" && <CountdownTimer />}
        </Box>
      </Box>
    </Box>
  );
};
