import styled from "styled-components";

interface IPayButton {
  isActive: boolean;
}

export const Wrapper = styled.div`
  max-width: 1360px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px 48px 48px 52px;
  background: #f8f8f8;
  border-radius: 10px;
  margin: 0 auto;
  @media (max-width: 768px) {
    padding: 8px;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 80px;
  @media (max-width: 1400px) {
    flex-direction: column;
    gap: 16px;
  }
`;

export const InfoContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  gap: 15px;
`;

export const InfoSubContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #ffffff;
  @media (max-width: 768px) {
    border: 1px solid #b9b9b9;
  }
`;

export const PaymentContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  span {
    text-wrap: wrap;
  }
`;

export const PayButton = styled.div<IPayButton>`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 16px;
  border-radius: 25px;
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #ffffff;
  background: ${(props) => (props.isActive ? "#e31340" : "grey")};
  cursor: pointer;
  @media (max-width: 500px) {
    width: 80%;
  }
`;

export const LogoWrapper = styled.div`
  width: 100%;
  margin-left: 26px;
  margin-top: 10px;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;
