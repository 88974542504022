import React, { useEffect, useRef } from "react";
import { ContentPicture } from "src/components";
import { Box } from "@mui/material";
import { TicketConditionsGroup } from "src/components/atoms/TicketConditionsGroup";
import VisitConditions from "src/components/atoms/VisitConditions";

import iconButton from "src/assets/formIcon.svg";

import mainPhoto from "../../../assets/Visualove_puda_u_Jakuba-5.jpg";

export const PageGroup = () => {
  const topRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (topRef && topRef.current) {
      window.scrollTo({
        top: topRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <Box ref={topRef}>
      <ContentPicture
        title={"visit_with_company"}
        buttonText={"group_form"}
        subtitle={"jacobs_church_in_brno"}
        mainPhoto={mainPhoto}
        click={() =>
          open("https://helpdesk.visitjakub.cz/desk/form/346e03a8e7924444935876343c668696")
        }
        buttonIcon={iconButton}
      />
      <TicketConditionsGroup />
      <Box my={6} sx={{ px: "18px" }}>
        <VisitConditions />
      </Box>
    </Box>
  );
};
