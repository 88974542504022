import { Box, CircularProgress } from "@mui/material";
import FullScreenTicketButton from "src/components/atoms/FullScreenTicketButton";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { styles } from "./styles";
import styless from "./styles.module.css";
import { useGetTickets } from "src/core/queries/useGetTickets";
import { useDispatch, useSelector } from "react-redux";
import ticketIcon from "../../../assets/ticket_red.svg";
import AmountPeopleComponent from "src/components/molecules/AmountPeopleComponent";
import { setToNextStep, setStepToPen } from "src/core/utils";
import { CheckOutStep, Tab, TourList, TourName } from "src/core/types";
import { RootState } from "src/core/redux/store";
import { IPreReservation, IPreReservationResponse, ITour, SlotKey } from "core/types/bundles";
import { CounterState, ErrorsState, StateValue } from "src/core/types/action";
import { prepareTicketToIncrement } from "src/core/utils/prepareTicketToIncrement";
import { prepareTicketToDecrement } from "src/core/utils/prepareTicketToDecrement";
import { usePostPreReservation } from "src/core/queries/usePostPreReservation";
import { prepareUpdateReservationData } from "src/core/utils/prepareUpdateReservationData";
import { setCapacity } from "src/core/redux/actions/capacityAction";
import TicketsReceipt from "src/components/atoms/TicketsReceipt";
import NextStepButton from "src/components/atoms/NextStepButton";
import { Loc } from "src/core/types/loc";
import PenComponent from "src/components/molecules/PenComponent";
import {
  PenWrapper,
  TicketDescription,
} from "src/components/molecules/AmountOfPeople/styledComponents";
import { PenTicketsIcon } from "src/components/icons/PenTicketsIcon";
import {
  setSelectedTickets as setGlobalSelectedTickets,
  setTickets,
} from "src/core/redux/actions/reservationDataActions";
import { defaultErrorsState } from "src/core/lists/ticketTypes";

interface AmountOfPeopleProps {
  updateCheckOutState: (newTabsState: Tab[], newPanelsState: CheckOutStep[]) => void;
  step: CheckOutStep;
  panels: CheckOutStep[];
  style: React.CSSProperties;
  tabs: Tab[];
  nextStep: string;
  shouldBePensShown: boolean;
}
const AmountOfPeople: React.FC<AmountOfPeopleProps> = ({
  updateCheckOutState,
  step,
  panels,
  tabs,
  nextStep,
  shouldBePensShown,
}) => {
  const dispatch = useDispatch();
  const reservationId = useSelector((state: RootState) => state.reservationID?.reservationID);
  const selectedSlots = useSelector((state: RootState) => state.timeSlot);
  const selectedBundle = useSelector((state: RootState) => state.bundle);
  const selectedTours = useSelector((state: RootState) => state.selectedTours);
  const baseCapacity = useSelector((state: RootState) => state.capacity);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  const [selectedTickets, setSelectedTickets] = useState<CounterState>({} as CounterState);
  const [errorsState, setErrorsState] = useState<ErrorsState>({
    senior: false,
    family: false,
    child: false,
    baby: false,
    adult: false,
  });
  const isApp = sessionStorage.getItem("isApp");
  const loc: Loc = (sessionStorage.getItem("loc") as Loc) || "cs";

  const { t } = useTranslation();

  const transformedSlotsForQuery: { name: SlotKey; guided: boolean }[] = selectedBundle?.tours.map(
    (tour: ITour) => {
      return { name: tour.name, guided: selectedSlots[tour.name]?.is_guided };
    },
  );

  //queries
  const { data, status, refetch } = useGetTickets({
    bundleName: selectedBundle?.name || undefined,
    tours: transformedSlotsForQuery,
    pos: isApp ? "app-webview" : "web",
  });

  const getTotalAmount = (param?: CounterState): number => {
    let totalAmount = 0;
    if (selectedBundle && selectedBundle.tours) {
      selectedBundle.tours.forEach((tour: ITour) => {
        if (!param) {
          if (selectedTickets[tour.name]) {
            selectedTickets[tour.name].forEach(
              (ticket: StateValue) => (totalAmount += ticket.amount),
            );
          }
        }
        if (param && param[tour.name]) {
          param[tour.name].forEach((ticket: StateValue) => (totalAmount += ticket.amount));
        }
      });
    }
    return totalAmount;
  };
  const onSuccess = (
    response: IPreReservationResponse,
    transformedData?: IPreReservation,
    tour?: SlotKey,
    slotId?: number,
  ) => {
    dispatch(setCapacity(response.capacity));
    if (response.result === "OK" && tour && transformedData && transformedData.tickets && slotId) {
      const filteredTickets = transformedData.tickets.filter(
        (ticket) => ticket.time_slot_id === slotId,
      );
      setSelectedTickets((prevState) => ({ ...prevState, [tour]: filteredTickets }));
      setErrorsState((prevState) => ({ ...prevState, adult: false }));
      dispatch(setGlobalSelectedTickets({ ...selectedTickets, [tour]: filteredTickets }));
      sessionStorage.setItem(
        "totalTickets",
        String(getTotalAmount({ ...selectedTickets, [tour]: filteredTickets })),
      );
      return;
    }
    if (response.result === "LIMIT_EXCEEDED") {
      setErrorsState((prevState) => ({ ...prevState, adult: true }));
    }
  };

  const { mutate, status: mutateStatus } = usePostPreReservation({ onSuccess: onSuccess });

  const slotKeys: SlotKey[] | undefined = data ? (Object.keys(data) as SlotKey[]) : undefined;

  const getCountOfTickets = (tour: SlotKey, ticketId: number): number => {
    return selectedTickets[tour]?.find((ticket) => ticket.type === ticketId)?.amount || 0;
  };

  const getTourPrice = (tour: SlotKey): number => {
    let total = 0;
    if (
      data &&
      selectedBundle &&
      Array.isArray(data[tour]) &&
      Array.isArray(selectedTickets[tour])
    ) {
      data[tour].forEach((ticket: TourList) => {
        selectedTickets[tour].forEach((selectedTicket: StateValue) => {
          if (ticket.id === selectedTicket.type) {
            total += Number(ticket.price) * selectedTicket.amount;
          }
        });
      });
      return total;
    }
    return total;
  };

  const transformUpdateReservationData = (preParedState: CounterState) => {
    return prepareUpdateReservationData({
      reservation: reservationId ?? "",
      pos: isApp ? "app-webview" : "web",
      tickets: { ...selectedTickets, ...preParedState },
      slotKeys: slotKeys ?? [],
      selectedSlots: selectedSlots,
    });
  };

  const isToursSelected = (): boolean => {
    if (selectedBundle && selectedBundle.tours) {
      const tiketsState = selectedBundle?.tours.map((selectedTour: ITour) => {
        if (selectedTickets[selectedTour.name]) {
          return selectedTickets[selectedTour.name]?.length > 0;
        }
        return false;
      });
      return !tiketsState?.some((state) => !state);
    }
    return false;
  };

  const goToPreviousStep = () => {
    const { newTabs, newPanels } = setStepToPen({
      tabs: tabs,
      panels: panels,
      currentKey: Number(step.key),
    });
    updateCheckOutState(newTabs, newPanels);
  };

  const getIsCanBeIncremented = (tour: SlotKey, ticket: TourList): boolean => {
    const { name } = ticket;
    if (Object.keys(selectedTickets).length === 0 && name !== "child" && name !== "baby") {
      return true;
    }
    if (name === "child" || name === "baby") {
      const hasAdult = selectedTickets[tour]?.some(
        (_ticket) => _ticket.name === "adult" || _ticket.name === "senior",
      );
      setErrorsState((prevState) => ({
        ...prevState,
        [name]: !hasAdult,
      }));

      return !!hasAdult;
    }
    setErrorsState(defaultErrorsState);
    return true;
  };

  const handleIncrement = (tour: SlotKey, ticket: TourList) => {
    const capacity = baseCapacity[selectedSlots[tour].id];
    if (!capacity || mutateStatus === "loading" || !getIsCanBeIncremented(tour, ticket)) return;

    const preParedState = prepareTicketToIncrement({
      tour: tour,
      selectedTickets: selectedTickets,
      ticket: ticket,
      slot: selectedSlots[tour],
    });

    const transformedData = transformUpdateReservationData(preParedState as CounterState);

    const slotId = selectedSlots[tour].id;
    mutate({ transformedData, tour, slotId });
  };
  const handleDecrement = (tour: SlotKey, ticket: TourList) => {
    if (!getCountOfTickets(tour, ticket.id)) return;
    const preParedState = prepareTicketToDecrement({
      tour: tour,
      selectedTickets: selectedTickets,
      ticket: ticket,
      slot: selectedSlots[tour],
    });
    let adultsCounter = 0;
    let childsCounter = 0;
    preParedState[tour].forEach((_tour) => {
      if (_tour?.name === "adult" || _tour?.name === "senior" || _tour?.name === "family") {
        adultsCounter += 1;
      }
    });
    preParedState[tour].forEach((_tour) => {
      if (_tour?.name === "child" || _tour?.name === "baby") {
        childsCounter += 1;
      }
    });

    if (adultsCounter === 0 && childsCounter > 0) {
      const newTicketsState = selectedTickets[tour].filter(
        (__ticket) => __ticket?.name !== "child" && __ticket?.name !== "baby",
      );
      const newState = { ...selectedTickets, [tour]: newTicketsState };
      const rePreparedState = prepareTicketToDecrement({
        tour: tour,
        selectedTickets: newState,
        ticket: ticket,
        slot: selectedSlots[tour],
      });
      const transformedData = transformUpdateReservationData(rePreparedState as CounterState);
      const slotId = selectedSlots[tour].id;
      mutate({ transformedData, tour, slotId });
    } else {
      const transformedData = transformUpdateReservationData(preParedState as CounterState);
      const slotId = selectedSlots[tour].id;
      mutate({ transformedData, tour, slotId });
    }
  };

  const handleNextStep = () => {
    if (isToursSelected()) {
      const { newTabs, newPanels } = setToNextStep({
        tabs: tabs,
        panels: panels,
        currentKey: Number(step.key),
      });
      updateCheckOutState(newTabs, newPanels);
    }
  };

  const getErrorMessage = (slotKey: SlotKey, ticketName: TourName): string => {
    const ticketErrorMessages: Record<TourName, string> = {
      senior: "",
      family: `${t("tickets_limit")} ${baseCapacity[selectedSlots[slotKey].id]} ${t("places")}`,
      child: `${t("child_with_adult")}`,
      baby: `${t("child_with_adult")}`,
      adult: `${t("tickets_limit")} ${baseCapacity[selectedSlots[slotKey].id]} ${t("places")}`,
    };
    return ticketErrorMessages[ticketName];
  };

  const getTourName = (tour: SlotKey): string => {
    if (selectedTours) {
      return selectedTours[tour].title[loc];
    }
    return "";
  };

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  useEffect(() => {
    setSelectedTickets({} as CounterState);
  }, [selectedSlots, reservationId]);

  useEffect(() => {
    if (data) {
      const ticketTypes = (Object.keys(data) as SlotKey[]).flatMap((tour: SlotKey) => data[tour]);
      dispatch(setTickets(ticketTypes));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    void refetch();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSlots]);

  return step.isEnabled ? (
    <Box className={styless.container}>
      <Box className={styless.title_row}>
        <Box style={styles.title}>
          <img src={ticketIcon} alt="ticket" style={{ marginRight: "10px", width: "24px" }} />
          {t("choose_ticket")}
        </Box>
      </Box>
      {status !== "success" && <CircularProgress color={"error"} />}
      {data &&
        status === "success" &&
        slotKeys &&
        slotKeys.length > 0 &&
        slotKeys.map((slotKey: SlotKey, i: number) => (
          <>
            <Box className={styless.notesBox}>
              {t("free-capacity")}
              <span className={styless.notes}>
                {` ${baseCapacity[selectedSlots[slotKey].id]} ${t("free-capacity-count")}`}
              </span>
            </Box>
            <div className={styless.ticketsBox}>
              <Box className={styless.mainBox}>
                <div className={styless.tour_name}>{getTourName(slotKey)}</div>
                <Box className={styless.innerBox}>
                  {data[slotKey]
                    .sort((a, b) => a.position - b.position)
                    .map((ticket: TourList) => (
                      <AmountPeopleComponent
                        key={ticket.id}
                        title={ticket.name}
                        price={Number(ticket.price)}
                        count={getCountOfTickets(slotKey, ticket.id)}
                        isError={errorsState[ticket.name]}
                        status={"success"}
                        errorMessage={getErrorMessage(slotKey, ticket.name)}
                        increment={() => handleIncrement(slotKey, ticket)}
                        decrement={() => handleDecrement(slotKey, ticket)}
                        isPermanentMessage={ticket.name === "child"}
                        bundle={selectedBundle}
                      />
                    ))}
                </Box>
              </Box>
              {slotKeys.length === i + 1 && (
                <TicketsReceipt getTourPrice={getTourPrice} bundle={selectedBundle} />
              )}
            </div>
          </>
        ))}
      <div className={styless.buttonNext}>
        {isMobile ? (
          <FullScreenTicketButton
            nextStep={t("contant_button")}
            onClick={() => isToursSelected() && handleNextStep()}
          />
        ) : (
          <NextStepButton
            text={t(nextStep)}
            handler={handleNextStep}
            isActive={isToursSelected()}
          />
        )}
      </div>
    </Box>
  ) : step.isPen && shouldBePensShown ? (
    <PenComponent prevStep={goToPreviousStep}>
      <PenWrapper>
        <PenTicketsIcon />
        <TicketDescription>{`${t("amount_of_tickets")}   ${getTotalAmount()}`}</TicketDescription>
      </PenWrapper>
    </PenComponent>
  ) : (
    <div></div>
  );
};
AmountOfPeople.displayName = "AmountOfPeople";
export default AmountOfPeople;
