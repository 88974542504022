import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { styles } from "./styles";

type TGiftCardPriceBadgeProps = {
  label: string;
  price: number;
  onClick: () => void;
  isSelected?: boolean;
};

export const GiftCardPriceBadge: React.FC<TGiftCardPriceBadgeProps> = ({
  label,
  price,
  onClick,
  isSelected = false,
}) => {
  const { t } = useTranslation();
  return (
    <Box
      width="100%"
      py={1}
      px={1}
      sx={[styles.container, isSelected && styles.containerSelected]}
      onClick={onClick}
    >
      <Stack direction="column" justifyContent="space-between" alignItems="center" height="100%">
        <Typography variant="body1" color="#1D1B20" gutterBottom fontSize="16px">
          {label}
        </Typography>
        <Typography variant="body1" color="#1D1B20" fontWeight="bold" className="cash-price">
          {price} {t("cz_currency")}
        </Typography>
      </Stack>
    </Box>
  );
};
