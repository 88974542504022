import { useParams } from "react-router-dom";
import { PageSuccessfulResult } from "src/components/pages/PageSuccessfulResult";
import { useGetGiftPaymentResult } from "src/core/queries/useGetGiftPaymentResult";
import React, { useEffect, useState } from "react";
import { PageErrorResult } from "../PageErrorResult";
import "./spinner.css";
import { PageSpinner } from "../PageSpinner";
import PageSuccessfulResultDesktop from "src/components/pages/PageSuccessfulResultDesktop";

export const PageWaitingForGiftResult: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const { reservationId } = useParams();

  const { data: paymentData, error: fetchError } = useGetGiftPaymentResult({
    reservationId: reservationId || "",
  });

  const updateMedia = (): void => {
    setIsMobile(window.innerWidth <= 768);
  };

  const getSuccessResult = (): JSX.Element => {
    return isMobile ? <PageSuccessfulResult /> : <PageSuccessfulResultDesktop />;
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
  }, []);

  if (fetchError) {
    return <PageErrorResult />;
  }
  const renderPage = () => {
    if (paymentData) {
      switch (paymentData.status) {
        case "COMPLETED":
          return getSuccessResult();
        case "CANCELED":
          return <PageErrorResult />;
        case "PENDING":
          return <PageSpinner />;
        default:
          return <PageSpinner />;
      }
    }
    return <PageSpinner />;
  };
  return <div>{renderPage()}</div>;
};
