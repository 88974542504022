import React, { useMemo } from "react";
import { Box, Stack } from "@mui/material";
import { SlotKey } from "src/core/types/bundles";
import { StyledParagraphText } from "src/components/atoms/StyledParagraphText";
import { useTranslation } from "react-i18next";

import { StyledParagraphTextDangerous } from "src/components/atoms/StyledParagraphTextDangerous";
import { SecondarySection } from "src/components/molecules";
import VisitConditions from "src/components/atoms/VisitConditions";
import { useGetTickets } from "src/core/queries/useGetTickets";
import { ButtonPurchaseTicketsSimple } from "src/components/atoms/ButtonPurchaseTicketsSimple";

import trussBrown from "../../../assets/img/guided_tours/truss_brown.jpg";
import styles from "./styles.module.css";

export const GuidedTourContent = ({
  isMobile,
  handleRedirectToBundle,
}: {
  isMobile: boolean;
  handleRedirectToBundle: (tour: SlotKey) => void;
}) => {
  const { t } = useTranslation();
  const adventTourAttractions = [
    t("tab_advent_tours_church"),
    t("tab_advent_tours_christmas_decks"),
    t("tab_advent_tours_tower"),
  ];
  const { data } = useGetTickets({
    bundleName: "advent",
    tours: [{ name: "advent", guided: true }],
    pos: "web",
  });
  const adultTicket = useMemo(() => data?.advent?.find((tour) => tour.name === "adult"), [data]);

  return (
    <Stack
      direction="column"
      alignItems="flex-start"
      className={styles.content_wrapper}
      gap={isMobile ? 1 : 4}
      mx={isMobile ? "8px" : "auto"}
      px={isMobile ? 0 : "18px"}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
        my={isMobile ? 3 : 5}
      >
        <Box className={styles.main_heading}>{t("tour_guides_article_heading")}</Box>
        <StyledParagraphText
          sx={{ fontSize: isMobile ? "1.125rem" : "1.375rem", textAlign: "center" }}
        >
          {t("tour_guides_article_subheading")}
        </StyledParagraphText>
      </Stack>
      <Stack
        direction={isMobile ? "column-reverse" : "row-reverse"}
        spacing={4}
        mt={4}
        justifyContent="start"
      >
        <Stack
          direction="column"
          gap={2}
          alignItems="flex-start"
          justifyContent="start"
          maxWidth="700px"
          sx={{ ":first-of-type": { marginTop: "1.2rem" } }}
        >
          <Box className={styles.sub_heading}>{t("tab_advent_tours_heading")}</Box>
          <StyledParagraphText sx={{ fontWeight: 700 }} gutterBottom>
            {t("tab_advent_tours_subheading")}
          </StyledParagraphText>

          <StyledParagraphText sx={{ fontWeight: 700 }} gutterBottom>
            {t("tab_advent_tours_atmosphere")}
          </StyledParagraphText>

          <Stack direction="column" my={2}>
            <StyledParagraphText sx={{ color: "#062762" }}>
              {t("tab_advent_tours_actions_heading")}
            </StyledParagraphText>

            <Stack direction="column" sx={{ ml: 2 }}>
              {adventTourAttractions.map((text) => (
                <Box key={text}>
                  <SecondarySection
                    text={text}
                    TextAsNode={
                      <StyledParagraphTextDangerous text={text} sx={{ color: "#062762" }} />
                    }
                  />
                </Box>
              ))}
            </Stack>
          </Stack>

          <StyledParagraphText
            sx={{ fontWeight: 700, textAlign: "center", fontSize: "22px", color: "#062762", mx: 4 }}
          >
            {t("tab_advent_tours_call_to_action")}
          </StyledParagraphText>

          <Box width="100%" mx="auto" display="flex" alignItems="center" flexDirection="column">
            <Stack direction="row" gap={1} alignItems="center" my={2}>
              <StyledParagraphText sx={{ fontSize: "20px", color: "#E31340" }}>
                {t("tab_advent_tours_base_price")}
              </StyledParagraphText>
              <StyledParagraphText sx={{ fontWeight: 700, fontSize: "22px", color: "#E31340" }}>
                {adultTicket?.price} {t("cz_currency")}
              </StyledParagraphText>
            </Stack>
            <ButtonPurchaseTicketsSimple onClick={() => handleRedirectToBundle("advent")} />
          </Box>
        </Stack>

        <img src={trussBrown} className={styles.guided_tour_img} alt="bell" />
      </Stack>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        mt={4}
        sx={isMobile ? { ">div": { width: "100%" } } : {}}
      >
        <VisitConditions />
      </Box>
    </Stack>
  );
};
