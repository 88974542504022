import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { Modal, Popover } from "@mui/material";
import { useTranslation } from "react-i18next";
import { HeadphonesIcon } from "src/components/icons/HeadphonesIcon";
import { RedPoint } from "src/components/icons/RedPoint";
import cross from "../../../assets/cross_red.png";
import { InfoIcon } from "src/components/icons/InfoIcon";
import { ExpositionSmall } from "src/components/atoms";

interface ModalComponentProps {
  open: boolean;
  handleClose: () => void;
}

const ModalVisitingFour: React.FC<ModalComponentProps> = ({ open, handleClose }): JSX.Element => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 768);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);
  return (
    <>
      {!isMobile ? (
        <Modal
          open={open}
          onClose={handleClose}
          sx={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ zIndex: 500, background: "#fff", borderRadius: "10px" }}>
            <div className={styles.header}>
              {t("visit_info_footer")}
              <img src={cross} onClick={handleClose} alt="" />
            </div>
            <div className={styles.wrapper}>
              <div className={styles.sub_wrapper}>
                <div className={styles.content_block}>
                  <HeadphonesIcon />
                  <span className={styles.text_default}>
                    {t("modal_visit_rules_rules_m_three")}
                  </span>
                </div>
                <div className={styles.content_block}>
                  <RedPoint />
                  <span className={styles.text_red_bold}>
                    {t("modal_visit_rules_rules_m_three_head")}{" "}
                    <span className={styles.text_default}>
                      {t("modal_visit_rules_rules_m_three_one")}
                    </span>
                    <span className={styles.text_red}>(22 min. )</span>
                  </span>
                </div>
                <div className={styles.content_block}>
                  <RedPoint />
                  <span className={styles.text_red_bold}>
                    {t("modal_visit_rules_rules_m_three_head_two")}{" "}
                    <span className={styles.text_default}>
                      {t("modal_visit_rules_rules_m_three_two")}
                    </span>
                    <span className={styles.text_red}>(42 min. )</span>
                  </span>
                </div>
                <div className={styles.small_description}>
                  <RedPoint />
                  {t("modal_visit_rules_rules_m_three_three")}
                </div>
                <div className={styles.content_block}>
                  <span className={styles.text_extra_bold}>20 - 40 min</span>
                  <span className={styles.text_default}>
                    {t("modal_visit_rules_rules_m_three_head_three")}
                  </span>
                </div>
                <div className={styles.content_block}>
                  <span className={`${styles.text_extra_bold_short} ${styles.text_mutated}`}>
                    5 min
                  </span>
                  <span className={styles.text_default}>
                    {t("modal_visit_rules_rules_m_three_four")}
                  </span>
                </div>
                <div className={styles.content_block}>
                  <span className={`${styles.text_extra_bold_short} ${styles.text_mutated}`}>
                    2 min
                  </span>
                  <span
                    className={styles.text_default}
                    dangerouslySetInnerHTML={{
                      __html: isMobile
                        ? t("modal_visit_rules_rules_m_three_head_four_mobile")
                        : t("modal_visit_rules_rules_m_three_head_four"),
                    }}
                  />
                </div>
                <div className={styles.warning}>
                  <div className={styles.warning_icon}>
                    <InfoIcon />
                  </div>
                  <span
                    className={styles.text_default}
                    dangerouslySetInnerHTML={{
                      __html: t("modal_visit_rules_rules_m_three_head_five"),
                    }}
                  />
                </div>
              </div>
              <ExpositionSmall />
            </div>
          </div>
        </Modal>
      ) : (
        <Popover open={open} onClose={handleClose}>
          <div style={{ zIndex: 500 }}>
            <div className={styles.header}>
              {t("visit_info_footer")}
              <img src={cross} onClick={handleClose} alt="" />
            </div>
            <div className={styles.wrapper}>
              <div className={styles.sub_wrapper}>
                <div className={styles.content_block}>
                  <HeadphonesIcon />
                  <span className={styles.text_default}>
                    {t("modal_visit_rules_rules_m_three")}
                  </span>
                </div>
                <div className={styles.content_block}>
                  <RedPoint />
                  <span className={styles.text_red_bold}>
                    {t("modal_visit_rules_rules_m_three_head")}{" "}
                    <span className={styles.text_default}>
                      {t("modal_visit_rules_rules_m_three_one")}
                    </span>
                    <span className={styles.text_red}>(22 min.)</span>
                  </span>
                </div>
                <div className={styles.content_block}>
                  <RedPoint />
                  <span className={styles.text_red_bold}>
                    {t("modal_visit_rules_rules_m_three_head_two")}{" "}
                    <span className={styles.text_default}>
                      {t("modal_visit_rules_rules_m_three_two")}
                    </span>
                    <span className={styles.text_red}>(42 min.)</span>
                  </span>
                </div>
                <div className={styles.small_description}>
                  <RedPoint />
                  {t("modal_visit_rules_rules_m_three_three")}
                </div>
                <div className={styles.content_block}>
                  <span className={styles.text_extra_bold}>20 - 40 min</span>
                  <span className={styles.text_default}>
                    {t("modal_visit_rules_rules_m_three_head_three")}
                  </span>
                </div>
                <div className={styles.content_block}>
                  <span className={styles.text_extra_bold_short}>5 min</span>
                  <span className={styles.text_default}>
                    {t("modal_visit_rules_rules_m_three_four")}
                  </span>
                </div>
                <div className={styles.content_block}>
                  <span className={styles.text_extra_bold_short}>2 min</span>
                  <span
                    className={styles.text_default}
                    dangerouslySetInnerHTML={{
                      __html: isMobile
                        ? t("modal_visit_rules_rules_m_three_head_four_mobile")
                        : t("modal_visit_rules_rules_m_three_head_four"),
                    }}
                  />
                </div>
                <div className={styles.warning}>
                  <div className={styles.warning_icon}>
                    <InfoIcon />
                  </div>
                  <span
                    className={styles.text_default}
                    dangerouslySetInnerHTML={{
                      __html: t("modal_visit_rules_rules_m_three_head_five"),
                    }}
                  />
                </div>
              </div>
              <ExpositionSmall />
            </div>
          </div>
        </Popover>
      )}
    </>
  );
};

export default ModalVisitingFour;
