import { Box } from "@mui/material";
import { PRE_RESERVATION_ROUTE } from "src/core/routing";
import { setSelectedBundle } from "src/core/redux/actions/bundleActions";
import { SlotKey } from "src/core/types/bundles";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetBundles } from "src/core/queries/useGetBundles";
import React, { useEffect, useRef, useState } from "react";
import { ContentPicture } from "src/components/molecules/ContentPicture";
import { useFormValuesContext } from "src/core/context";
import styles from "./styles.module.css";

//assets
import mobilePhoto from "../../../assets/img/guided_tours/cover_mobile.jpg";
import desktopPhoto from "../../../assets/img/guided_tours/cover.webp";
import { visitorsCategories } from "src/core/lists";
import { GuidedTourContent } from "src/components/organisms";

export const PageGuidedTours = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const isApp = sessionStorage.getItem("isApp");
  const { formValues, setFormValues } = useFormValuesContext();
  const formRef = useRef<HTMLDivElement>(null);
  const topRef = useRef<HTMLDivElement>(null);

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const { data } = useGetBundles();

  const handleRedirectToBundle = (tour: SlotKey) => {
    if (data) {
      const bundleToSelect = data.find((bundle) => bundle.name === tour);
      if (bundleToSelect) {
        dispatch(setSelectedBundle(bundleToSelect));
        localStorage.setItem("selectedBundle", JSON.stringify(bundleToSelect));
        navigate(PRE_RESERVATION_ROUTE);
      }
    }
  };

  useEffect(() => {
    setFormValues({ ...formValues, finalPrice: visitorsCategories[6].price });
    sessionStorage.setItem("totalPrice", String(visitorsCategories[6].price));
  }, [formValues, setFormValues]);

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  const mainPhoto = isMobile ? mobilePhoto : desktopPhoto;

  const handleButtonClick = (): void => {
    if (formRef && formRef.current) {
      window.scrollTo({
        top: formRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (topRef && topRef.current) {
      window.scrollTo({
        top: topRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <Box ref={topRef}>
      <ContentPicture
        mainPhoto={mainPhoto}
        title={"tours_with_tower_climb"}
        subtitle={""}
        click={handleButtonClick}
        hasButton={false}
        buttonText={""}
        className={styles.header_util}
      />
      <GuidedTourContent isMobile={isMobile} handleRedirectToBundle={handleRedirectToBundle} />
    </Box>
  );
};
