export const styles = {
  container: {
    cursor: "pointer",
    maxWidth: "160px !important",
    backgroundColor: "#fff",
    borderRadius: "5px",
    border: "1px solid #B9B9B9",
    "&:hover": {
      backgroundColor: "#fff",
      borderColor: "#E31340",
    },
    "*": {
      cursor: "pointer",
    },
    ".MuiTypography-root": {
      color: "#1D1B20",
    },
  },
  containerSelected: {
    borderColor: "#E31340",
    ".cash-price": {
      color: "#062762",
    },
  },
};
