import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const CheckIconAlt: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon {...props} width="13.764" height="10" viewBox="0 0 13.764 10">
    <svg xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(0)">
        <path
          d="M196.26,422.95a.545.545,0,0,1-.386-.159l-3.765-3.765a.545.545,0,0,1,.771-.771l3.38,3.379,8.523-8.524a.545.545,0,0,1,.771.771l-8.909,8.909A.54.54,0,0,1,196.26,422.95Z"
          transform="translate(-191.95 -412.95)"
          fill="#E6264C"
        />
      </g>
    </svg>
  </SvgIcon>
);
