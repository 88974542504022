import React from "react";

import { PanelChurchContent } from "src/components/molecules";
import { JacobsTowerContent } from "src/components/molecules/JacobsTowerContent";
import { MorgueContent } from "src/components/molecules/MorgueContent";
import { PanelChurchContentMoreInfo } from "src/components/molecules/PanelChurchContentMoreInfo";
import { JacobsTowerContentMoreInfo } from "src/components/molecules/JacobsTowerContentMoreInfo";
import { MorgueContentMoreInfo } from "src/components/molecules/MorgueContentMoreInfo";

import { IMiddlePanel } from "src/core/types/middlePanel";

import experience from "../../assets/img/global/multimedia_exposition.jpg";
import tower from "../../assets/img/homepage/articlePhotos/jacob_tower.png";
import morgue from "../../assets/img/homepage/articlePhotos/morgue.jpg";

export const middlePanels: IMiddlePanel[] = [
  {
    title: "unique_experience",
    image: experience,
    content: <PanelChurchContent />,
    operator: "JK Trust",
    moreInfo: <PanelChurchContentMoreInfo />,
  },
  {
    title: "jakub_tower",
    image: tower,
    content: <JacobsTowerContent />,
    operator: "JK Trust",
    moreInfo: <JacobsTowerContentMoreInfo />,
    isFlexReversed: true,
  },
  {
    title: "in_jakub",
    image: morgue,
    content: <MorgueContent />,
    operator: "TIC Brno",
    moreInfo: <MorgueContentMoreInfo />,
  },
];
