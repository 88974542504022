import { Typography } from "@mui/material";
import React from "react";

export const StyledParagraphText = ({
  children,
  gutterBottom,
  sx,
}: {
  children: React.ReactNode;
  gutterBottom?: boolean;
  sx?: any;
}) => {
  return (
    <Typography
      variant="body1"
      fontWeight={400}
      color={sx?.color ?? "#000"}
      bgcolor="#ffffff"
      sx={sx}
      gutterBottom={gutterBottom}
    >
      {children}
    </Typography>
  );
};
