import React from "react";

//components
import map from "../../../assets/google_map_kostel 1.png";
import cross from "../../../assets/cross_red.png";
import styles from "./styles.module.css";
import Transport from "src/components/atoms/Transport";
import { transport } from "src/core/helpers/transport";
import { useTranslation } from "react-i18next";

export const ModalSchedule = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <span className={styles.header_text}>{t("how_to_enter")}</span>
        <img alt="cross" onClick={onClose} src={cross} className={styles.cross} />
      </div>
      <div className={styles.sub_header}>
        <span className={styles.sub_header_text_one}>{t("how_to_find")}</span>
        <span className={styles.sub_header_text_two}>{t("how_to_find_adress")}</span>
      </div>
      <div className={styles.img_wrapper}>
        <img alt="map" src={map} />
      </div>
      {transport.map((el) => (
        <Transport
          key={el.tName}
          header={t(el.header)}
          icon={el.icon}
          tName={t(el.tName)}
          numbers={el.numbers}
        />
      ))}
    </div>
  );
};
