import { CounterState } from "src/core/types/action";
import { SlotKey, SlotState } from "core/types/bundles";

export const prepareUpdateReservationData = ({
  reservation,
  pos,
  tickets,
  slotKeys,
  selectedSlots,
}: {
  reservation: string;
  pos: string;
  tickets: CounterState;
  slotKeys: SlotKey[];
  selectedSlots: SlotState;
}) => {
  const mappedTickets =
    slotKeys?.flatMap((key) => (Array.isArray(tickets[key]) ? tickets[key].map((t) => t) : [])) ||
    [];

  return {
    reservation: reservation,
    pos: pos,
    tickets: mappedTickets,
    slots: (Object.keys(selectedSlots) as SlotKey[]).map((key: SlotKey) => selectedSlots[key].id),
  };
};
