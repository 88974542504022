import React from "react";
import { useNavigate } from "react-router-dom";
import { AppBar, Box, Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { headerRoutes } from "src/core/utils";
import { ButtonPurchaseTickets } from "src/components/atoms/ButtonPurchaseTickets";
import { Logo } from "src/components";
import MobileMenu from "src/components/atoms/MobileMenu";
import { GIFT_CARDS_ROUTE } from "src/core/routing";

import { style } from "./style";
import styles from "./styles.module.css";

const HomeHeader: React.FC<{
  VisitClick?: () => void;
  purchaseTicketsClick?: () => void;
}> = ({ purchaseTicketsClick, VisitClick }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <AppBar color="inherit">
      <div className={styles.wrapper}>
        <div className={styles.sidebar}>
          <Box sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
            <Logo />
            <div className={styles.desktop_only}>
              <div className={styles.route_wrapper}>
                <div className={styles.routes}>
                  {headerRoutes.map((route) => (
                    <Button
                      key={route.path}
                      onClick={() => {
                        navigate(route.path);
                      }}
                    >
                      <Typography style={style.buttonText}>{t(route.label)}</Typography>
                    </Button>
                  ))}
                </div>
              </div>
              <Button variant="outlined" onClick={() => navigate(GIFT_CARDS_ROUTE)}>
                {t("gift_cards")}
              </Button>
            </div>
          </Box>
          <Stack direction="row" gap={1} alignItems="center">
            <div className={styles.desktop_only}>
              <ButtonPurchaseTickets />
              <Button
                variant="outlined"
                onClick={() => {
                  open("https://helpdesk.visitjakub.cz/desk");
                }}
              >
                {t("visitor_infoservice")}
              </Button>
              {/* notice: add back when functionality implemented */}
              {/*<div className={styles.cart_container}>*/}
              {/*  <CartIcon />*/}
              {/*</div>*/}
            </div>
            <div className={styles.mobile_only}>
              <MobileMenu VisitClick={VisitClick} onButtonsClick={purchaseTicketsClick} />
            </div>
          </Stack>
        </div>
      </div>
    </AppBar>
  );
};
export default HomeHeader;
