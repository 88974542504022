import styled from "styled-components";

interface IWrapper {
  isRecapPayment: boolean;
}

export const Wrapper = styled.div<IWrapper>`
  width: ${(props) => (props.isRecapPayment ? "100%" : "341px")};
  max-height: 312px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 10px;
  align-items: center;
  padding: 24px 24px 10px 24px;
  background: #ffffff;

  @media (max-width: 768px) {
    width: 100%;
    margin: 20px auto 0 auto;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Logo = styled.img`
  width: 85px;
  height: 44px;
`;

export const HeaderText = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: right;
  color: #000000;
`;

export const PriceWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SlotName = styled.span`
  max-width: 190px;
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #062762;
`;

export const MiddleWrapper = styled.div<IWrapper>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
  gap: 15px;
`;

export const SlotPrice = styled.div`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: right;
  color: #062762;
`;

export const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #b9b9b9;
`;

export const TotalPriceWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  gap: 16px;
  padding: 15px 0;
`;

export const TotalPriceItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TotalPriceText = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #000000;
`;

export const TotalPrice = styled.span`
  font-family: Montserrat, serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #000000;
`;

export const TotalPriceCash = styled.span`
  color: #062762;
`;

export const Discount = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #e31340;
`;
