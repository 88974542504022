import { RecapPaymentGiftCards } from "components";
import React, { forwardRef, SetStateAction, useEffect, useState } from "react";
import AmountOfPeople from "src/components/molecules/AmountOfPeople";
import { Panel } from "src/components/molecules/Panel";
import { PersonalData } from "src/components/molecules/PersonalData";
import DateAndTime from "src/components/molecules/DateAndTime";

import { CheckOutStep, Tab } from "src/core/types";
import style_css from "./styles.module.css";

import colorCalendar from "../../../assets/kalendar_cerveny.svg";
import blueCalendar from "../../../assets/kalendar_sedy.svg";

import colorTicket from "../../../assets/Activeticket.svg";
import grayTicket from "../../../assets/BasicTicket.svg";

import colorPersonal from "../../../assets/kontaktni_udaje_cervene.svg";
import grayPersonal from "../../../assets/kontaktni_udaje_svetle_sede.svg";
import bluePersonal from "../../../assets/kontaktni_udaje_sede.svg";

import colorPayment from "../../../assets/payment_color.svg";
import grayPayment from "../../../assets/payment_gray.svg";
import bluePayment from "../../../assets/payment_blue.svg";

import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import ReservationComponent from "src/components/molecules/ReservationComponent";

export interface ICheckOutState {
  tabs: Tab[];
  panels: CheckOutStep[];
}
interface ICheckOutProps {
  setTimer: (value: boolean) => void;
  setIsTabTwoActive?: React.Dispatch<SetStateAction<boolean>>;
  setCurrentTab?: React.Dispatch<SetStateAction<string | null | number>>;
}

const CheckOut = forwardRef<HTMLDivElement, ICheckOutProps>(
  ({ setTimer, setIsTabTwoActive, setCurrentTab }, ref) => {
    const [activeTab] = useState<string>("data_time_chosen");
    const [nextStep, setNextStep] = useState<string>("");
    const [shouldBePensShown, setShouldBePensShown] = useState<boolean>(true);

    const { t } = useTranslation();

    const [checkOutState, setCheckOutState] = useState<ICheckOutState>({
      tabs: [
        {
          title: t("data_time_chosen"),
          icon: blueCalendar,
          activeIcon: colorCalendar,
          doneIcon: blueCalendar,
          isTabActive: true,
          isTabDone: false,
          key: "1",
        },
        {
          title: t("select_ticket"),
          icon: grayTicket,
          activeIcon: colorTicket,
          doneIcon: grayTicket,
          isTabActive: false,
          isTabDone: false,
          key: "2",
        },
        {
          title: t("contact_information"),
          icon: grayPersonal,
          activeIcon: colorPersonal,
          doneIcon: bluePersonal,
          isTabActive: false,
          isTabDone: false,
          key: "3",
        },
        {
          title: t("recap_and_payment"),
          icon: grayPayment,
          activeIcon: colorPayment,
          doneIcon: bluePayment,
          isTabActive: false,
          isTabDone: false,
          key: "4",
        },
      ],
      panels: [
        {
          description: "Panel",
          isEnabled: false,
          key: "0",
          component: Panel,
          isStepFinished: false,
          isPen: false,
        },

        {
          description: t("data_time_chosen"),
          isEnabled: true,
          key: "1",
          component: DateAndTime,
          isStepFinished: false,
          isPen: false,
        },
        {
          description: t("How_many_people_will_come?"),
          isEnabled: false,
          key: "2",
          component: AmountOfPeople,
          isStepFinished: false,
          isPen: false,
        },
        {
          description: t("contact_information"),
          isEnabled: false,
          key: "3",
          component: PersonalData,
          isStepFinished: false,
          isPen: false,
        },
        {
          description: t("recap_and_payment_title"),
          isEnabled: false,
          key: "4",
          component: ReservationComponent,
          isStepFinished: false,
          isPen: false,
        },
      ],
    });

    const setUpKey = (): boolean => {
      const foundKey = checkOutState.tabs.find((tab) => tab.key === "2");
      return foundKey?.isTabActive || false;
    };

    const updateCheckOutState = (newTabsState: Tab[], newPanelsState: CheckOutStep[]) => {
      setCheckOutState((prevState) => {
        return {
          ...prevState,
          tabs: newTabsState,
          panels: newPanelsState,
        };
      });
    };

    const updateIsPaymentEnabled = (): boolean => {
      const enabledPanelKey = checkOutState.panels.find((panel) => panel.isEnabled);
      return enabledPanelKey?.key !== "4";
    };

    useEffect(() => {
      if (setIsTabTwoActive) {
        setIsTabTwoActive(setUpKey());
      }
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkOutState.tabs]);

    useEffect(() => {
      setShouldBePensShown(updateIsPaymentEnabled());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkOutState]);

    useEffect(() => {
      const currentStep = checkOutState.panels.find((panel) => panel.isEnabled);
      const index = currentStep && checkOutState.panels.indexOf(currentStep);
      if (index && index < checkOutState.panels.length) {
        const next = index + 1;
        if (checkOutState.panels[next]) {
          setNextStep(checkOutState.panels[next].description);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkOutState.panels, checkOutState.tabs]);

    useEffect(() => {
      if (setCurrentTab) {
        const foundTab = checkOutState.tabs.find((el: Tab) => el.isTabActive);
        setCurrentTab(foundTab?.key || "-1");
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkOutState.panels]);

    return (
      <Box className={style_css.box} ref={ref}>
        {checkOutState.panels.map((step) => {
          const Component = step.component;
          return (
            <Component
              shouldBePensShown={shouldBePensShown}
              setTimer={setTimer}
              key={step.key}
              step={step}
              tabs={checkOutState.tabs}
              activeTab={activeTab}
              panels={checkOutState.panels}
              updateCheckOutState={updateCheckOutState}
              checkOutState={checkOutState}
              nextStep={nextStep}
            />
          );
        })}
      </Box>
    );
  },
);
CheckOut.displayName = "CheckOut";
export default CheckOut;
