import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  @media (max-width: 768px) {
    padding: 16px 8px;
  }
`;

export const SubWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

export const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
`;

export const TitleText = styled.span`
  font-family: Montserrat, serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #062762;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const SlotWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const SlotItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  gap: 10px;
  color: #000000;
`;

export const HistoryItem = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  color: #000000;
`;

export const MainInfo = styled.span`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const AdditionalInfo = styled.span`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const ChangeButton = styled.div`
  width: 68px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

export const ChangeButtonText = styled.span`
  font-family: Montserrat, serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: #e6264c;
`;

export const CheckIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px 0 0;
`;
