import { Typography } from "@mui/material";
import { IBundle, ITour, SlotKey } from "core/types/bundles";
import React, { SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Discount, DiscountResult } from "src/core/types";
import { RootState } from "src/core/redux/store";
import { useGetDiscount } from "src/core/queries/useGetDiscount";
import { setBundleDiscount } from "src/core/redux/actions/bundleActions";

import {
  Button,
  Input,
  Message,
  MessageWrapper,
  SubWrapper,
  Wrapper,
} from "src/components/molecules/PaymentDiscountComponent/styledComponents";

const PaymentDiscountComponent = ({
  setCurrentDiscount,
  setDiscountInfo,
  getTourPrice,
  bundle,
}: {
  setCurrentDiscount: React.Dispatch<SetStateAction<string>>;
  setDiscountInfo: React.Dispatch<SetStateAction<Discount>>;
  getTourPrice: (tour: SlotKey) => number;
  bundle: IBundle;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const bundleDiscount = useSelector((state: RootState) => state.bundle?.discount);
  const [inputValue, setInputValue] = useState<string>("");
  const [error, setError] = useState<DiscountResult>("" as DiscountResult);

  const onSuccess = (data: Discount) => {
    if (error) setError("" as DiscountResult);
    setCurrentDiscount(inputValue);
    const { type, amount } = data;
    if (type && type.toLowerCase() === "percent") {
      if ((bundleDiscount || 0) >= amount) return;
      dispatch(setBundleDiscount(amount));
      setDiscountInfo({} as Discount);
      return;
    }
    setDiscountInfo(data);
  };

  const getTotalPrice = () => {
    let total = 0;
    bundle.tours.forEach((tour: ITour) => (total += getTourPrice(tour.name)));
    return total < 0 ? 0 : total;
  };

  const onError = (_error: DiscountResult) => {
    setError(_error);
  };

  const { data, refetch } = useGetDiscount({
    code: inputValue,
    onSuccess: onSuccess,
    onError: onError,
  });

  const getErrorMessage = (errorType: DiscountResult) => {
    switch (errorType) {
      case "INVALID":
        return `${t("invalid_discount")}`;
      case "EXPIRED":
        return `${t("discount_expired")}`;
      case "ALREADY_USED":
        return `${t("discount_already_used")}`;
      default:
        if (data && data.type === "flat") {
          return getTotalPrice() - (data.amount || 0) < 0
            ? t("gift_more_than")
            : t("gift_smaller_than");
        }
        return "";
    }
  };

  const handleInvokeDiscount = () => {
    if (inputValue.length > 0) void refetch();
  };

  return (
    <Wrapper>
      <Typography variant="body2" color="#000">
        {t("discount_code_label")}
      </Typography>
      <SubWrapper>
        <Input value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
        <Button onClick={handleInvokeDiscount} isActive={true}>
          {t("usage")}
        </Button>
      </SubWrapper>
      <MessageWrapper>
        <Message>{`${getErrorMessage(error)}`}</Message>
      </MessageWrapper>
    </Wrapper>
  );
};

export default PaymentDiscountComponent;
