import activeMinus from "src/assets/minus-red.svg";
import minus from "src/assets/minus_gray.svg";
import unActivePlus from "src/assets/plus_color.svg";
import plus from "src/assets/unActivePlus.svg";
import { ButtonCount, ButtonDecrement, ButtonIncrement, Buttons } from "./styledComponents";
import React from "react";

type TCounterProps = {
  count: number;
  isButtonsAvailable: boolean;
  handleDecrement: () => void;
  handleIncrement: () => void;
};

export const Counter = ({
  count,
  isButtonsAvailable,
  handleDecrement,
  handleIncrement,
}: TCounterProps) => {
  return (
    <Buttons>
      <ButtonDecrement onClick={handleDecrement} isActive={isButtonsAvailable && count > 0}>
        <img src={count > 0 && isButtonsAvailable ? activeMinus : minus} alt="minus" />
      </ButtonDecrement>
      <ButtonCount>{`${count}`}</ButtonCount>
      <ButtonIncrement onClick={handleIncrement} isActive={isButtonsAvailable}>
        <img src={isButtonsAvailable ? plus : unActivePlus} alt="plus" />
      </ButtonIncrement>
    </Buttons>
  );
};
