import { useQuery } from "react-query";
import axios from "axios";
import { TICKETS_API_ROUTE } from "../endpoints";
import { TourList } from "../types";
import { SlotKey } from "core/types/bundles";

export const useGetTickets = ({
  bundleName,
  tours,
  pos,
}: {
  bundleName: string | undefined;
  tours: { name: SlotKey; guided?: boolean }[];
  pos: "app-webview" | "web";
}) => {
  const fetchTickets: () => Promise<{ [key in SlotKey]: TourList[] }> = async () => {
    const res = await axios.post(`${window.BASE_API_URL}/${TICKETS_API_ROUTE}/${bundleName}`, {
      tours: tours,
      pos: pos,
    });
    return res.data;
  };

  return useQuery(`fetchTickets-${bundleName}`, fetchTickets, {
    refetchOnWindowFocus: false,
    enabled: true,
  });
};
