import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { CrossIcon } from "src/components/icons/CrossIcon";
import NumberedComponent from "src/components/atoms/SecurityData/SubComponents/NumberedComponent";
import {
  numberedContentIIIone,
  numberedContentIIIthree,
  numberedContentIIItwo,
  numberedContentIIone,
  numberedContentIItwo,
  numberedContentIone,
  numberedContentIThree,
  numberedContentIVone,
  numberedContentIVtwo,
  numberedContentVone,
  rulesListIIIone,
  rulesListIIItwo,
  rulesListIVone,
  rulesListVone,
  numberedContentVtwo,
  numberedContentVIone,
  rulesListVIone,
  numberedContentVItwo,
  numberedContentVIIone,
  numberedContentVIItwo,
  numberedContentVIIthree,
  numberedContentVIIIone,
  numberedContentVIIItwo,
  numberedContentVIIIthree,
} from "src/core/helpers/privacyPolicy";
import DataComponent from "src/components/atoms/SecurityData/SubComponents/DataComponent";
import DottedComponent from "src/components/atoms/SecurityData/SubComponents/DottedComponent";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SecurityData = ({
  handleClose,
  isModal,
}: {
  handleClose?: () => void;
  isModal?: boolean;
}): JSX.Element => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  const location = useLocation();
  const { t } = useTranslation();

  const updateMedia = (): void => {
    setIsMobile(window.innerWidth <= 768);
  };

  const getStyle = (): string => {
    return isModal ? styles.wrapper_modal : styles.wrapper;
  };

  const getCrossStyle = (): string => {
    return isModal ? styles.cross_wrapper_modal : styles.cross_wrapper;
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return (
    <div className={getStyle()}>
      {location.pathname !== "/privacy-policy" && (
        <div className={getCrossStyle()} onClick={handleClose}>
          <CrossIcon />
        </div>
      )}
      <div
        className={styles.wrapper_header}
        dangerouslySetInnerHTML={{
          __html: isMobile ? t("data_security_head_mob") : t("data_security_head"),
        }}
      />
      <div className={styles.content}>
        <div className={styles.content_header}>I.</div>
        <div className={styles.content_header}>{t("data_security_one_one")}</div>
        <NumberedComponent number={1} content={t(numberedContentIone)} />
        <NumberedComponent number={2} content={<DataComponent />} />
        <NumberedComponent number={3} content={t(numberedContentIThree)} />
        <div className={styles.content_header}>II.</div>
        <div className={styles.content_header}>{t("data_security_two")}</div>
        <NumberedComponent number={1} content={t(numberedContentIIone)} />
        <NumberedComponent number={2} content={t(numberedContentIItwo)} />
      </div>
      <div className={styles.content_header}>III.</div>
      <div className={styles.content_header}>{t("data_security_three")}</div>
      <NumberedComponent number={1} content={t(numberedContentIIIone)} />
      <DottedComponent items={rulesListIIIone} />
      <NumberedComponent number={2} content={t(numberedContentIIItwo)} />
      <DottedComponent items={rulesListIIItwo} />
      <NumberedComponent number={3} content={t(numberedContentIIIthree)} />
      <div className={styles.content_header}>IV.</div>
      <div className={styles.content_header}>{t("data_security_four_head")}</div>
      <NumberedComponent number={1} content={t(numberedContentIVone)} />
      <DottedComponent items={rulesListIVone} />
      <NumberedComponent number={2} content={t(numberedContentIVtwo)} />
      <div className={styles.content_header}>V.</div>
      <div className={styles.content_header}>{t("data_security_five")}</div>
      <NumberedComponent number={1} content={t(numberedContentVone)} />
      <DottedComponent items={rulesListVone} />
      <NumberedComponent number={2} content={t(numberedContentVtwo)} />
      <div className={styles.content_header}>VI.</div>
      <div className={styles.content_header}>{t("data_security_six")}</div>
      <NumberedComponent number={1} content={t(numberedContentVIone)} />
      <DottedComponent items={rulesListVIone} />
      <NumberedComponent number={2} content={t(numberedContentVItwo)} />
      <div className={styles.content_header}>VII.</div>
      <div className={styles.content_header}>{t("data_security_seven")}</div>
      <NumberedComponent number={1} content={t(numberedContentVIIone)} />
      <NumberedComponent number={2} content={t(numberedContentVIItwo)} />
      <NumberedComponent number={3} content={t(numberedContentVIIthree)} />
      <div className={styles.content_header}>VIII.</div>
      <div className={styles.content_header}>{t("data_security_eight")}</div>
      <NumberedComponent number={1} content={t(numberedContentVIIIone)} />
      <NumberedComponent number={2} content={t(numberedContentVIIItwo)} />
      <NumberedComponent number={3} content={t(numberedContentVIIIthree)} />
      <div className={styles.bold_end}>{t("rules_date")}</div>
    </div>
  );
};

export default SecurityData;
