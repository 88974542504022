import React from "react";
import styles from "./styles.module.css";
import { CrossIcon } from "src/components/icons/CrossIcon";
import { useTranslation } from "react-i18next";

const SecurityVisitMobile = ({ handleClose }: { handleClose: () => void }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <div className={styles.cross_wrapper} onClick={handleClose}>
        <CrossIcon />
      </div>
      <div
        className={styles.header}
        dangerouslySetInnerHTML={{ __html: t("security_visit_mobile") }}
      />
      <div className={styles.sub_content_red}>{t("fire_warning")}</div>
      <div className={styles.sub_content}>{t("fire_manipulation")}</div>
      <div className={styles.sub_content}>{t("fire_desc_two")}</div>
      <div className={styles.sub_content}>{t("fire_desc_three")}</div>
      <div className={styles.sub_content}>
        <ol>
          <li dangerouslySetInnerHTML={{ __html: t("security_visit_one") }} />
          <li>{t("security_visit_two")}</li>
          <li>{t("security_visit_three")}</li>
          <li>{t("security_visit_four")}</li>
          <li>{t("security_visit_five")}</li>
        </ol>
      </div>
    </div>
  );
};

export default SecurityVisitMobile;
