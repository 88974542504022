import { createTheme, Radio, FormControlLabel, SvgIcon } from "@mui/material";
import palette from "./palette";
import typography from "./typography";
import { RadioIcon } from "src/components/icons/RadioIcon";
import { RadioCheckedIcon } from "src/components/icons/RadioCheckedIcon";

const CustomRadioIcon = () => (
  <SvgIcon>
    <circle cx="12" cy="12" r="10" stroke="#848484" strokeWidth="1px" fill="#FFFFFF" />
  </SvgIcon>
);

const CustomCheckedRadioIcon = () => (
  <SvgIcon>
    <circle cx="12" cy="12" r="10" stroke="#E31340" strokeWidth="1px" fill="#FFFFFF" />
    <circle cx="12" cy="12" r="5" fill="#E31340" />
  </SvgIcon>
);

const theme = createTheme({
  palette,
  typography,
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: "#E3E2E7",
          fontWeight: 400,
          fontSize: "16px",
          color: "#062762",
          display: "grid",
          gridTemplateColumns: "1fr 1.5fr",
          padding: "1vh 2vw",
          // "&.Mui-selected, &.Mui-selected:hover, &.Mui-focusVisible": {
          //   backgroundColor: "white",
          //   outline: "none",
          // },
          // "&:focus": {
          //   backgroundColor: "white",
          //   outline: "none",
          // },
          // "&:hover": {
          //   backgroundColor: "#E3E2E7",
          // },
          // "&:active": {
          //   backgroundColor: "#E3E2E7",
          //   color: "#062762",
          // },
        },
      },
    },

    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 0,
          textTransform: "none",
          fontSize: "1rem",
          lineHeight: 1.18,
          "&.MuiButton-text": {
            color: "#fff",
            fontWeight: 300,
            textDecoration: "underline",
            padding: 0,
            lineHeight: 1.29,
          },
          "&.MuiButton-outlined": {
            color: "#E6264C",
            borderColor: "#E6264C",
            borderRadius: "25px",
            fontWeight: 400,
            fontSize: "16px",
            padding: "13px 19px",
            height: "50px",
            "&:hover": {
              backgroundColor: "transparent",
            },
          },
          "&.MuiButton-contained": {
            minHeight: "50px",
            minWidth: "150px",
            background: "#E31340",
            color: "white",
            padding: "10px 20px",
            borderRadius: "25px",
            fontWeight: 400,
            "&:hover": {
              backgroundColor: "#F1375F",
              color: "#FFFFFF",
            },
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          backgroundColor: "#E6264C",
          color: "#fff",
          borderRadius: 0,
          width: "fit-content",
          height: "fit-content",
          padding: "10px 20px",
          "&:hover": {
            backgroundColor: "#F1718B",
          },
          "&:disabled": {
            backgroundColor: "#4D4D4D",
            color: "#848484",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderWidth: "1px",
          borderColor: "#79747E",
          color: "#000000",
          fontWeight: "400",
          ".MuiOutlinedInput-root": {
            borderColor: "#79747E",
          },
          "&:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#062762",
              borderWidth: "1px",
            },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          ".MuiInputBase-root": {
            color: "#fff",
            borderRadius: 0,
            border: "1px solid #fff",
            fontSize: "1.125rem",
            input: {
              padding: "9px 21px",
            },
          },
          ".MuiOutlinedInput-notchedOutline": {
            border: "0",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          position: "absolute",
          bottom: "-1.6rem",
          fontSize: "0.875rem",
          color: "#E6264C !important",
          margin: "4px 0 0 10px",
          whiteSpace: "nowrap",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: "1px",
          marginRight: "10px",
          svg: {
            width: "30px",
            height: "30px",
            fill: "#E31340",
          },
          position: "relative",
          "&.Mui-checked": {
            "& svg": {
              fill: "#F8F8F8",
              backgroundColor: "#E31340",
              borderColor: "#E31340",
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          borderRadius: 0,
          border: "1px solid white",
          display: "grid",
          gridTemplateColumns: "1fr 1.2fr",
          gap: "10px",
          color: "black",
          padding: "9px 12px",
          "&:hover": {
            border: "1px solid white",
            backgroundColor: "white",
          },
          '&[aria-expanded="true"]': {
            backgroundColor: "white",
            border: "1px solid white",
          },
          "& ~ .MuiOutlinedInput-notchedOutline": {
            border: "none",
            "&:hover": {
              border: "none",
            },
          },
        },
        icon: {
          color: "#E6264C",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          position: "relative",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          padding: 0,
          "&:first-of-type": {
            justifyContent: "end",
          },
          ".MuiStepConnector-root": {
            position: "relative",
            display: "flex",
            height: 2,
            backgroundColor: "#fff",
            top: "unset",
            left: "unset",
            right: "unset",
            "&.Mui-completed": {
              backgroundColor: "#E6264C",
            },
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          ".MuiStepLabel-iconContainer": {
            height: "2rem",
            width: "2rem",
            border: "2px solid #fff",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: 500,

            "&.Mui-completed": {
              borderColor: "#E6264C",
              color: "#E6264C",
            },
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: "black",
          fontWeight: "400",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "#848484",
          "&$checked": {
            color: "#E31340",
          },
        },
      },
      defaultProps: {
        icon: <CustomRadioIcon />,
        checkedIcon: <CustomCheckedRadioIcon />,
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          ".MuiPaper-root": {
            boxShadow: "0px 3px 6px 0px #00000029",
            borderRadius: "10px",
            margin: "-1px 0 0 -1px",
          },
        },
      },
    },
  },
});

export default theme;

// const theme = createTheme({
//   components: {
//     MuiButtonBase: {
//       styleOverrides: {
//         root: {
//           backgroundColor: "transparent",
//         },
//       },
//     },
//   },
// });
