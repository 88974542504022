import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 10px;
  background: #fff;
  @media (max-width: 768px) {
    border: 1px solid #b9b9b9;
  }
`;

export const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const KeyText = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #000000;
`;

export const ValueText = styled.span`
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #000000;
`;
