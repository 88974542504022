import React from "react";
import styles from "./styles.module.css";
import { CrossIcon } from "src/components/icons/CrossIcon";
import { useTranslation } from "react-i18next";

const SecurityVisit = ({ handleClose }: { handleClose: () => void }): JSX.Element => {
  const { t } = useTranslation();
  const text = [
    `<span style='color: red'>${t("fire_warning")}</span>`,
    t("fire_manipulation"),
    t("fire_desc_two"),
    t("fire_desc_three"),
  ];
  const olText = [
    t("security_visit_one"),
    t("security_visit_two"),
    t("security_visit_three"),
    t("security_visit_four"),
    t("security_visit_five"),
  ];
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>{t("security_visit")}</div>
      <div className={styles.content}>
        {text.map((inner_text: string, index) => (
          <div
            key={index}
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: inner_text }}
          />
        ))}
        <ol className={styles.ol_list}>
          {olText.map((ol_inner_text, index) => (
            <li key={index} dangerouslySetInnerHTML={{ __html: ol_inner_text }} />
          ))}
        </ol>
      </div>
      <div className={styles.cross_wrapper} onClick={handleClose}>
        <CrossIcon />
      </div>
    </div>
  );
};

export default SecurityVisit;
