export const styles = {
  line: {
    maxWidth: "1055px",
    width: "100%",
    height: 0,
    border: "1px dashed #707070",
    opacity: 1,
    margin: "20px auto",
  },
  giftCardPicker: {
    ">img": {
      width: "100%",
      height: "100%",
      maxWidth: "550px",
      maxHeight: "550px",
      "@media (max-width: 768px)": {
        display: "none",
      },
    },
  },
};
