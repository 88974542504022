import styled from "styled-components";

interface ISelectButton {
  isAllowed: boolean;
  warning?: boolean;
}

export const Wrapper = styled.div<ISelectButton>`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: ${(props) => (props.warning ? "1px solid #FFC107" : "1px solid #e1e1e1")};
  border-radius: 5px;
  gap: 10px;
  padding-right: 10px;
  background: #ffffff;

  &:hover {
    border: ${(props) => `1px solid ${props.isAllowed ? "red" : "grey"}`};
  }
`;

export const Col = styled.div`
  width: 125px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #062762;
  gap: 8px;
`;

export const TimeRow = styled.span`
  width: 120px;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  font-family: Montserrat, serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #062762;
`;

export const ActionsWrapper = styled.div`
  width: 120px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  margin-left: auto;
`;

export const SelectButton = styled.div<ISelectButton>`
  width: 76px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  border-radius: 25px;
  border: ${(props) => `1px solid ${props.isAllowed ? "#e31340" : "grey"}`};
  color: ${(props) => (props.isAllowed ? " #e31340" : "grey")};
  font-family: Montserrat, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  margin-left: auto;

  &:hover {
    background: ${(props) => (props.isAllowed ? "#e31340" : "#fff")};
    color: ${(props) => (props.isAllowed ? "#fff" : "grey")};
  }
`;

export const InnerWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
