import React from "react";

import { useTranslation } from "react-i18next";
import { Box, Stack } from "@mui/material";
import { Section } from "src/components/molecules";
import { StyledParagraphText } from "src/components/atoms/StyledParagraphText";
import { StyledParagraphTextDangerous } from "src/components/atoms/StyledParagraphTextDangerous";

export const PanelChurchContent = () => {
  const { t } = useTranslation();
  const visitAttractions = [
    t("tab_multimedia_experience_exterior_preview"),
    t("tab_multimedia_experience_view"),
    t("tab_multimedia_experience_tower_safe"),
    t("tab_multimedia_experience_deck"),
  ];

  const deckAttractions = [
    t("tab_multimedia_experience_projection"),
    t("tab_multimedia_experience_story"),
    t("tab_multimedia_experience_lights"),
  ];

  return (
    <Stack direction="column">
      <StyledParagraphText gutterBottom sx={{ fontWeight: 700 }}>
        {t("tab_multimedia_experience_sub_title_bold")}
      </StyledParagraphText>
      <StyledParagraphText gutterBottom>
        {t("tab_multimedia_experience_sub_title")}
      </StyledParagraphText>
      {visitAttractions.map((text) => (
        <Box sx={{ mb: 0.7 }} key={text}>
          <Section text={text} TextAsNode={<StyledParagraphTextDangerous text={text} />} />
        </Box>
      ))}
      <Box sx={{ ml: 5, mb: 2 }}>
        {deckAttractions.map((text) => (
          <Box sx={{ mb: 0.7 }} key={text}>
            <Section text={text} />
          </Box>
        ))}
      </Box>
    </Stack>
  );
};
