import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";

import penIcon from "src/assets/edit.svg";
import Tickets from "src/components/icons/Tickets";
import { useGiftCardPurchaseContext } from "src/core/context";

import styless from "src/components/molecules/PersonalData/style.module.css";

export const PaymentEditableGiftCardCount = () => {
  const navigate = useNavigate();
  const { giftCardCount } = useGiftCardPurchaseContext();
  const { t } = useTranslation();
  return (
    <Box className={styless.closedBox} m={{ xs: "0 8px 10px 8px", md: "0 auto 20px auto" }}>
      <div className={styless.rowBox}>
        <Tickets fill="#051C4766" width="24" height="24" sx={{ mr: "10px" }} />
        <Stack direction="row" gap={2}>
          <Typography sx={{ color: "#051C4766", fontWeight: "600" }}>
            {t("gift_cards_total")}:
          </Typography>
          <Typography sx={{ color: "#051C4766", fontWeight: "600" }}>{giftCardCount}</Typography>
        </Stack>
      </div>
      <img
        className={styless.pen}
        src={penIcon}
        onClick={() => navigate("/gift-cards")}
        alt="Pen Icon"
      />
    </Box>
  );
};
