import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ArrowRight from "src/components/icons/ArrowRight";
import React from "react";
import { Wrapper, InsideWrapper } from "src/components/pages/PageSetTypeOfTicket/styledComponents";
import PanelRow from "src/components/pages/PageSetTypeOfTicket/components/PanelRow";
import { useGetBundles } from "src/core/queries/useGetBundles";
import { IBundle } from "core/types/bundles";
import { Loc } from "src/core/types/loc";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";

const PageSetTypeOfTicket = () => {
  const loc: Loc = (sessionStorage.getItem("loc") as Loc) || "cs";
  const { data, status } = useGetBundles();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const isApp = sessionStorage.getItem("isApp") === "true";

  const { singleBundles, multipleBundles } = (data || []).reduce<{
    singleBundles: IBundle[];
    multipleBundles: IBundle[];
  }>(
    (acc, bundle) => {
      if (bundle.is_single) {
        acc.singleBundles.push(bundle);
      } else {
        acc.multipleBundles.push(bundle);
      }
      return acc;
    },
    { singleBundles: [], multipleBundles: [] },
  );

  return (
    <Wrapper>
      <InsideWrapper isApp={isApp}>
        {data && status === "success" && (
          <>
            <PanelRow
              title={t("choose_bundle")}
              panels={multipleBundles.sort((a, b) => a.position - b.position)}
              loc={loc}
            />
            <PanelRow
              title={t("choose_bundle_alternative")}
              panels={singleBundles.sort((a, b) => a.position - b.position)}
              loc={loc}
            />
          </>
        )}
        {status === "loading" && <CircularProgress color={"error"} />}
      </InsideWrapper>
      <Stack direction="column" gap={2} justifyContent="center" alignItems="center" mt={5} mx={2}>
        <Typography
          variant="body1"
          color="#062762"
          fontWeight="bold"
          fontSize={24}
          textAlign="center"
        >
          {t("tour_guides_ad_heading")}
        </Typography>
        <Typography variant="body1" color="#062762" fontSize={24} textAlign="center">
          {t("tour_guides_ad_more_info")}
        </Typography>
        <Button variant="contained" color="primary" onClick={() => navigate("/guided-tours")}>
          <Stack direction="row" alignItems="center" gap={1}>
            {t("guided_tours_alt")}
            <ArrowRight color="#fff" size={{ w: "25", h: "25" }} />
          </Stack>
        </Button>
      </Stack>
    </Wrapper>
  );
};

export default PageSetTypeOfTicket;
