import React from "react";
import { IItem, ISlot } from "core/types/bundles";
import { useTranslation } from "react-i18next";
import {
  Bundle,
  BundleInfoWrapper,
  BundleName,
  BundleNameWrapper,
  DateWrapper,
  HistoryName,
} from "src/components/molecules/ReservationComponent/components/TourComponent/styledComponents";
import ChangeComponent from "src/components/molecules/ReservationComponent/components/ChangeComponent";
import { SmallKalendar } from "src/components/icons/SmallKalendar";
import { getFormattedDateToText } from "src/core/helpers/getFormattedDateToText";
import { SmallVolume } from "src/components/icons/SmallVolume";
import { Loc } from "src/core/types/loc";

const TourComponent = ({
  item,
  prevTicketStep,
  prevDateStep,
  tourName,
  loc,
  date,
  ticketAmount,
  slot,
}: {
  item: IItem;
  prevTicketStep: () => void;
  prevDateStep: () => void;
  ticketAmount: number;
  date: Date | undefined;
  loc: Loc;
  slot: ISlot;
  tourName: string;
}) => {
  const { t } = useTranslation();
  return (
    <Bundle>
      <BundleNameWrapper>
        <BundleName>{tourName}</BundleName>
      </BundleNameWrapper>
      <BundleInfoWrapper>
        {slot && slot.story_name && <HistoryName>{slot.story_name[loc] || ""}</HistoryName>}
        <ChangeComponent prevStep={prevDateStep} type={t("date_and_time")}>
          <DateWrapper>
            <SmallKalendar />
            {slot && slot.time && `${getFormattedDateToText(date)}, ${slot.time.slice(0, 5)}`}
            <SmallVolume />
            <span>{(slot && slot.language && slot.language.toUpperCase()) || ""}</span>
          </DateWrapper>
        </ChangeComponent>
        <ChangeComponent prevStep={prevTicketStep} type={"Celkem vstupenek"}>
          <div>{ticketAmount}</div>
        </ChangeComponent>
      </BundleInfoWrapper>
    </Bundle>
  );
};

export default TourComponent;
